import { alertService, authService, customerService, tenantService } from '../services'
import {
  CHILDREN,
  CURRENT_LIVING_CONDITION,
  DEATH_OF_PARTNER,
  DIVORCE,
  FINANCING,
  getAge,
  getHousingRequirements,
  getNotInState,
  getRoutes,
  HOUSING_REQUIREMENTS,
  INCAPACITY_WORK,
  isHypotheekwacht,
  MORTGAGE,
  MORTGAGE_AND_PENSION,
  MOVING,
  PENSION,
  PERSONAL_CHARACTERISTICS,
  PERSONAL_DETAILS,
  QUESTIONS_FOR_THE_ADVISOR,
  router,
  SOURCE_DATA,
  SUSTAINABILITY,
  UNEMPLOYMENT
} from '../helpers'
import * as Sentry from '@sentry/vue'

const survey = JSON.parse(localStorage.getItem('survey'))

const salutations = [
  { value: 'Beste heer', label: 'Beste heer', show: true },
  { value: 'Beste mevrouw', label: 'Beste mevrouw', show: true },
  { value: 'Geachte heer', label: 'Geachte heer', show: true },
  { value: 'Geachte mevrouw', label: 'Geachte mevrouw', show: true }
]

const companyLegalTypeOptions = [
  { value: 'none', label: 'Maak een keuze', show: true },
  { value: 'one_man_business', label: 'Eenmanszaak', show: true },
  { value: 'society', label: 'Maatschapij', show: true },
  { value: 'partnership', label: 'VOF', show: true },
  { value: 'corporation', label: 'CV', show: true },
  { value: 'private_company', label: 'BV', show: true },
  { value: 'limited_company', label: 'NV', show: true },
  { value: 'foundation', label: 'Stichting', show: true }
]

const homeTypeOptions = [
  { value: 'eengezinswoning', label: 'Eengezinswoning', show: true },
  { value: 'bungalow', label: 'Bungalow', show: true },
  { value: 'hoekwoning', label: 'Hoekwoning', show: true },
  { value: 'appartement', label: 'Appartement', show: true },
  { value: 'tussenwoning', label: 'Tussenwoning', show: true },
  { value: '2-onder-1-kapwoning', label: 'Twee onder een kap', show: true },
  { value: 'vrijstaand', label: 'Vrijstaande woning', show: true },
  { value: 'grachtenpand', label: 'Grachtenpand', show: true },
  { value: 'herenhuis', label: 'Herenhuis', show: true },
  { value: 'benedenwoning', label: 'Benenedenwoning', show: true },
  { value: 'bovenwoning', label: 'Bovenwoning', show: true }
]

const aflosvormen = [
  { value: 'none', label: 'Maak een keuze', variants: [], show: true },
  { value: 5, label: 'Aflossingsvrij', variants: ['Aflosvrij', 'Aflossingvrij'], show: true },
  { value: 2, label: 'Lineair', variants: [], show: true },
  { value: 1, label: 'Annuiteit', variants: ['Annuitair', 'Annuïteit', 'Annuïtair'], show: true },
  { value: 50, label: 'Banksparen', variants: [], show: true },
  { value: 3, label: 'Levensverzekering', variants: ['Leven'], show: true },
  {
    value: 10,
    label: 'Beleggingshypotheek',
    variants: ['Beleggen', 'Beleggingsrekening'],
    show: true
  },
  { value: 9, label: 'Krediet of bankhypotheek', variants: [], show: false },
  { value: 13, label: 'Spaarrekening', variants: [], show: false },
  { value: 51, label: 'Bausparen', variants: [], show: false },
  { value: 52, label: 'Starterslening', variants: [], show: false },
  { value: 53, label: 'Spaarrekening', variants: ['Sparen'], show: false }
]

const rentesoorten = [
  { value: 'none', label: 'Maak een keuze', show: true },
  { value: 'fixed', label: 'Vast', show: true },
  { value: 'variable', label: 'Variabel', show: true }
]

const hypotheekgaranties = [
  { value: 'nhg', label: 'Ja', show: true },
  { value: 'none', label: 'Nee', show: true }
]

const radiusOptions = [
  { value: 1, label: '1km', show: true },
  { value: 5, label: '5km', show: true },
  { value: 10, label: '10km', show: true },
  { value: 15, label: '15km', show: true }
]

const burgelijkeStaat = [
  { value: -1, label: 'Niet bekend', show: true },
  { value: 0, label: 'Alleenstaand', show: true },
  { value: 1, label: 'Gehuwd', show: true },
  { value: 2, label: 'Gehuwd met huwelijksevoorwaarden', show: true },
  { value: 7, label: 'Gescheiden', show: true },
  { value: 9, label: 'Inwonend', show: true },
  { value: 3, label: 'Partnerregistratie', show: true },
  { value: 4, label: 'Partnerregistratie onder registratievoowaarden', show: true },
  { value: 5, label: 'Samenwonend met samenlevingscontract', show: true },
  { value: 6, label: 'Samenwonend zonder samenlevingscontract', show: true },
  { value: 8, label: 'Weduwe/Weduwenaar', show: true }
]

const genders = [
  { value: 'm', label: 'Man', show: true },
  { value: 'f', label: 'Vrouw', show: true }
]

export const initialState = {
  isHypotheekwacht: false,
  status: {
    version: 166,
    loading: false,
    personal_details: false,
    personal_characteristics: false,
    current_living_condition: false,
    moving: false,
    children: false,
    unemployment: false,
    incapacity_work: false,
    housing_requirements: false,
    sustainability: false,
    death_of_partner: false,
    divorce: false,
    pension: false,
    mortgage: false,
    mortgage_and_pension: false,
    questions_for_the_advisor: false
  },
  aflosvormen,
  style: {
    loading: false,
    tenant_id: 999,
    name: '',
    full_name: '',
    logo: '',
    hero_image: './assets/hero-image.jpg',
    h1: {
      color: '#333333',
      size: '2.625em'
    },
    h2: {
      color: '#333333',
      size: '1.875em'
    },
    h3: {
      color: '#333333',
      size: '1.675em'
    },
    h4: {
      color: '#333333',
      size: '1.475em'
    },
    primary: {
      backgroundColor: '#333333',
      borderColor: '#333333',
      color: '#ffffff'
    },
    danger: {
      backgroundColor: '#ed1c24',
      borderColor: '#ed1c24',
      color: '#ffffff'
    },
    success: {
      backgroundColor: '#4BAF50',
      borderColor: '#4BAF50',
      color: '#ffffff'
    },
    warning: {
      backgroundColor: '#ec9b00',
      borderColor: '#ec9b00',
      color: '#ffffff'
    },
    use_slogan: false,
    slogan: '',
    use_contact_mail: false,
    contact_mail: '',
    use_support_mail: false,
    support_mail: ''
  },
  hdn_companies: {
    loading: false,
    companies: []
  },
  hdn_company_labels: {
    loading: false,
    labels: []
  },
  files: [],
  parseResult: {},
  customer: {
    id: {
      title: 'id',
      description: '',
      value: null,
      type: 'number',
      error: null
    },
    is_main_customer: {
      title: 'Is hoofdklant',
      description: '',
      type: 'bool',
      value: true,
      error: null
    },
    is_partner: {
      title: 'Is partner',
      description: '',
      type: 'bool',
      value: false,
      error: null
    },
    salutation: {
      title: 'Aanhef',
      description: '',
      value: '',
      type: 'option',
      options: salutations,
      error: null
    },
    initials: {
      title: 'Voorletter(s)',
      description: '',
      value: '',
      type: 'text',
      error: null
    },
    official_first_names: {
      title: 'Officiële voornaam',
      description: '',
      value: '',
      type: 'text',
      error: null
    },
    first_name: {
      title: 'Roepnaam',
      description: '',
      value: '',
      type: 'text',
      error: null
    },
    insertion: {
      title: 'Tussenvoegsel',
      description: '',
      value: '',
      type: 'text',
      error: null
    },
    last_name: {
      title: 'Achternaam',
      description: '',
      value: '',
      type: 'text',
      error: null
    },
    date_of_birth: {
      title: 'Geboortedatum',
      description: '',
      value: '',
      type: 'date',
      error: null
    },
    gender: {
      title: 'Geslacht',
      description: '',
      value: 'm',
      type: 'option',
      options: genders,
      error: null
    },
    smoking: {
      title: 'Laatste 24 maanden gerookt?',
      description: '',
      value: -1,
      type: 'option',
      options: [
        { value: 1, label: 'Ja', show: true },
        { value: 0, label: 'Nee', show: true }
      ],
      error: null
    },
    maritial_status: {
      title: 'Burgerlijke staat',
      description: '',
      value: -1,
      options: burgelijkeStaat,
      type: 'option',
      error: null
    },
    customer_number: {
      title: 'Lidnummer VEH',
      description: '',
      value: '',
      type: 'text',
      error: null
    },
    accept_terms: {
      title: 'Accepteer de algemene voorwaarden',
      description: '',
      value: -1,
      type: 'bool',
      error: null
    },
    has_partner: {
      title: 'Heeft u een partner?',
      description: '',
      options: [
        { value: 1, label: 'Ja', show: true },
        { value: 0, label: 'Nee', show: true }
      ],
      value: -1,
      type: 'option',
      error: null
    },
    has_children: {
      title: 'Heeft u kinderen?',
      description: '',
      options: [
        { value: 1, label: 'Ja', show: true },
        { value: 0, label: 'Nee', show: true }
      ],
      value: -1,
      type: 'option',
      error: null
    },
    has_employers: {
      title: 'Bent u op dit moment volledig werkzaam (geen sprake van verzuim)?',
      description: '',
      options: [
        { value: 1, label: 'Ja', show: true },
        { value: 0, label: 'Nee', show: true }
      ],
      value: -1,
      type: 'option',
      error: null
    },
    has_companies: {
      title: 'Staat u en/of uw partner ingeschreven bij de Kamer van Koophandel?',
      description: '',
      options: [
        { value: 1, label: 'Ja', show: true },
        { value: 0, label: 'Nee', show: true }
      ],
      value: 0,
      type: 'option',
      error: null
    },
    is_pensioned: {
      title: 'Bent u met pensioen?',
      description: '',
      options: [
        { value: 1, label: 'Ja', show: true },
        { value: 0, label: 'Nee', show: true }
      ],
      value: -1,
      type: 'option',
      error: null
    },
    has_mortgage_with_partner: {
      title: 'Wilt u samen met uw partner een hypotheek nemen?',
      description: '',
      options: [
        { value: 1, label: 'Ja', show: true },
        { value: 0, label: 'Nee', show: true }
      ],
      value: -1,
      type: 'option',
      error: null
    },
    phone: {
      title: 'Telefoonnummer',
      description: '',
      value: '',
      type: 'phone',
      error: null
    },
    email: {
      title: 'E-mail',
      description: '',
      value: '',
      type: 'email',
      error: null
    },
    address: {
      street: {
        title: 'Straat',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      number: {
        title: 'Nummer',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      addition: {
        title: 'Toevoeging',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      postal_code: {
        title: 'Postcode',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      place: {
        title: 'Plaats',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      woz_waarde: {
        title: 'WOZ Waarde',
        description: '',
        value: 0,
        type: 'amount',
        error: null
      },
      marktwaarde_woning: {
        title: 'Marktwaarde woning',
        description: '',
        value: 0,
        type: 'amount',
        error: null
      }
    },
    invitation: {
      id: {
        title: 'Uitnodiging',
        description: '',
        value: survey?.customer?.invitation?.id.value ?? null,
        type: 'text',
        aqopi_info: null,
        error: null
      },
      type: {
        title: 'Soort',
        description: '',
        value: survey?.customer?.invitation?.type.value ?? SOURCE_DATA,
        type: 'text',
        error: null
      },
      partner_invited: {
        title: 'Partner uitgenodigd',
        description: '',
        value: survey?.customer?.invitation?.partner_invited.value ?? false,
        type: 'bool',
        error: null
      },
      valid_until: {
        title: 'Geldig t/m',
        description: '',
        value: survey?.customer?.invitation?.valid_until.value ?? null,
        type: 'text',
        error: null
      },
      submitted_at: {
        title: 'Aangeleverd op',
        description: '',
        value: survey?.customer?.invitation?.submitted_at.value ?? null,
        type: 'text',
        error: null
      }
    },
    partner: {
      id: {
        title: 'id',
        description: '',
        value: null,
        type: 'number',
        error: null
      },
      salutation: {
        title: 'Aanhef',
        description: '',
        value: '',
        type: 'option',
        options: salutations,
        error: null
      },
      first_name: {
        title: 'Roepnaam',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      official_first_names: {
        title: 'Officiële voornaam',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      insertion: {
        title: 'Tussenvoegsel',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      last_name: {
        title: 'Achternaam',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      date_of_birth: {
        title: 'Geboortedatum',
        description: '',
        value: '',
        type: 'date',
        error: null
      },
      gender: {
        title: 'Geslacht',
        description: '',
        value: 'Maak een keuze',
        type: 'option',
        options: genders,
        error: null
      },
      different_address: {
        title: 'Mijn partner woont op een ander adres',
        description: '',
        value: 0,
        type: 'bool',
        error: null
      },
      phone: {
        title: 'Telefoonnummer',
        description: '',
        value: '',
        type: 'phone',
        error: null
      },
      email: {
        title: 'E-mail',
        description: '',
        value: '',
        type: 'email',
        error: null
      },
      salaris_bruto_per_maand: {
        title: 'Salaris (bruto per jaar)',
        description: '',
        value: 0,
        type: 'amount',
        error: null
      },
      address: {
        street: {
          title: 'Straat',
          description: '',
          value: '',
          type: 'text',
          error: null
        },
        number: {
          title: 'Nummer',
          description: '',
          value: '',
          type: 'text',
          error: null
        },
        addition: {
          title: 'Toevoeging',
          description: '',
          value: '',
          type: 'text',
          error: null
        },
        postal_code: {
          title: 'Postcode',
          description: '',
          value: '',
          type: 'text',
          error: null
        },
        place: {
          title: 'Plaats',
          description: '',
          value: '',
          type: 'text',
          error: null
        }
      }
    },
    office: {
      id: {
        title: 'id',
        description: '',
        value: 0,
        type: 'number',
        error: null
      },
      code: {
        title: 'Code',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      email: {
        title: 'E-mail',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      name: {
        title: 'Naam',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      number: {
        title: 'Nummer',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      phone: {
        title: 'Telefoonnummer',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      website: {
        title: 'Website',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      street_name: {
        title: 'Straat',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      house_number: {
        title: 'Nummer',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      house_number_addition: {
        title: 'Toevoeging',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      postcode: {
        title: 'Postcode',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      city: {
        title: 'Plaats',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      style: {
        logo: {
          title: 'Logo',
          description: '',
          value: null,
          type: 'text',
          error: null
        },
        content_type: {
          title: 'Type',
          description: '',
          value: null,
          type: 'text',
          error: null
        }
      }
    },
    children: [],
    employers: [],
    companies: [],
    pensions: [],
    mortgages: []
  },
  personal_characteristics: {
    question_1: {
      title: 'In het bepalen van mijn keuzes ben ik...',
      description: '',
      value: 2,
      error: null
    },
    question_2: {
      title: 'In de omgang met mensen ben ik...',
      description: '',
      value: 2,
      error: null
    },
    question_3: {
      title: 'In lastige situaties ben ik...',
      description: '',
      value: 2,
      error: null
    },
    question_4: {
      title: 'In het plannen en uitzoeken ben ik...',
      description: '',
      value: 2,
      error: null
    }
  },
  current_living_condition: {
    title: 'Woonsituatie',
    description: 'In deze stap gaan we in op uw woonsituatie en -wensen op korte termijn. Onderstaande vragen geven een beeld van uw voorkeuren en de mogelijkheden voor een (nieuwe) hypotheek. Zo kan de adviseur gericht kijken naar de opties die op uw situatie aansluiten.',
    sections: {
      section_1: {
        title: 'Algemeen',
        description: 'In hoeverre bent u bekend met de mogelijkheden die er zijn om uw (toekomstige) woning te verduurzamen?',
        questions: {
          i_have_a_house: {
            title: 'Heeft u een koophuis?',
            description: '',
            options: [
              { value: 0, label: 'Ik heb nog geen koophuis', show: true },
              { value: 1, label: 'Ik heb al een koophuis', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          i_want_a_new_house: {
            title: 'Gaat u een nieuw huis kopen?',
            description: '',
            options: [
              { value: 0, label: 'Ik ga kopen', show: true },
              { value: 1, label: 'Ik blijf wonen', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          i_want_a_existing_house: {
            title: 'Wat voor woning wilt u kopen?',
            description: '',
            options: [
              { value: 0, label: 'Ik wil een bestaande woning kopen', show: true },
              { value: 1, label: 'Ik wil een nieuwbouwwoning kopen', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          new_house: {
            title: 'Heeft u al een nieuwe woning op het oog?',
            description: '',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          postal_code: {
            title: 'Postcode',
            description: '',
            value: '',
            type: 'string',
            error: null
          },
          number: {
            title: 'Nummer',
            description: '',
            value: '',
            type: 'string',
            error: null
          },
          addition: {
            title: 'Toevoeging',
            description: '',
            value: '',
            type: 'string',
            error: null
          },
          jumba: {
            title: 'Mijn woning',
            description: '',
            type: 'jumba',
            jumba_id: {
              title: 'Gewenste woning',
              value: '',
              type: 'string',
              error: null
            },
            value_low: {
              title: 'Waarde (laag)',
              value: 0,
              type: 'number',
              error: null
            },
            value_high: {
              title: 'Waarde (hoog)',
              value: 0,
              type: 'number',
              error: null
            }
          }
        }
      },
      section_3: {
        title: 'Inkomen',
        description: '',
        questions: {
          whos_the_owner: {
            title: 'Wie is of wordt de eigenaar van de woning?',
            value: -1,
            type: 'option',
            options: [
              { value: 0, label: 'Ik', show: true },
              { value: 1, label: 'Mijn partner', show: true },
              { value: 2, label: 'Mijn partner en ik', show: true },
              { value: 3, label: 'Andere verdeling:', show: true }
            ],
            error: null,
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string'
            }
          },
          whos_income: {
            title: 'Welk inkomen wilt u mee laten tellen voor het bereken van de verantwoorde hypotheekhoogte?',
            value: -1,
            type: 'option',
            options: [
              { value: 0, label: 'Alleen mijn inkomen', show: true },
              { value: 1, label: 'Alleen het inkomen van mijn partner', show: true },
              { value: 2, label: 'Zowel het inkomen van mijn partner, als van mij', show: true }
            ],
            error: null,
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string'
            }
          }
        }
      },
      section_4: {
        title: 'Woonsituatie',
        description: '',
        questions: {
          live_style: {
            title: 'In welke mate is uw huidige inkomen toereikend voor uw levensstijl?',
            value: -1,
            type: 'option',
            options: [
              {
                value: 0,
                label: 'Mijn inkomen is ruim voldoende; ik kan nu veel sparen',
                show: true
              },
              { value: 1, label: 'Mijn inkomen is voldoende, maar ik spaar beperkt', show: true },
              { value: 2, label: 'Mijn inkomen is net voldoende; ik kan niet sparen', show: true },
              { value: 3, label: 'Mijn inkomen is ontoereikend', show: true }
            ],
            error: null,
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string'
            }
          },
          my_income_5_years: {
            title: 'Hoe verwacht u dat het eigen inkomen zich de komende vijf jaar gaat ontwikkelen?',
            value: -1,
            type: 'option',
            options: [
              { value: 0, label: 'Mijn inkomen gaat dalen', show: true },
              { value: 1, label: 'Mijn inkomen blijft gelijk', show: true },
              { value: 2, label: 'Mijn inkomen gaat stijgen met 1 tot 3% per jaar', show: true },
              {
                value: 3,
                label: 'Mijn inkomen gaat stijgen met gemiddeld meer dan 3% per jaar',
                show: true
              }
            ],
            error: null,
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string'
            }
          },
          max_netto_mortgage: {
            title: 'Welk bedrag wilt u per maand maximaal uitgeven aan netto hypotheeklasten (dus na teruggaaf van de belastingdienst), inclusief eventuele risicoverzekering(en)?',
            value: 0,
            type: 'amount',
            error: null,
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string'
            }
          },
          moving: {
            title: 'Verhuizen naar volgende woning',
            value: -1,
            type: 'radio',
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ],
            error: null
          },
          heritage: {
            title: 'Een erfenis/schenking',
            value: -1,
            type: 'radio',
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ],
            error: null
          },
          family_expansion: {
            title: 'Gezinsuitbreiding',
            value: -1,
            type: 'radio',
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ],
            error: null
          },
          study_costs_children: {
            title: 'Studiekosten kinderen',
            value: -1,
            type: 'radio',
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ],
            error: null
          },
          working_less: {
            title: 'Minder werken',
            value: -1,
            type: 'radio',
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ],
            error: null
          },
          other_possibility: {
            title: 'Andere relevante verandering',
            value: -1,
            type: 'radio',
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ],
            error: null
          },
          changes_financial_state_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string',
            error: null
          },
          saving_goals: {
            title: 'Welke spaardoelen heeft u?',
            required: 1,
            type: 'multiplechoice',
            questions: [{
              title: 'Financiële buffer (minimaal 6x de netto hypotheeklasten)',
              value: 0,
              show: true
            }, {
              title: 'Vakanties en reizen',
              value: 0,
              show: true
            }, {
              title: 'Woningonderhoud of verbouwing',
              value: 0,
              show: true
            }, {
              title: 'Aanvulling op pensioen',
              value: 0,
              show: true
            }, {
              title: 'Grote consumptieve uitgave, zoals bijvoorbeeld een auto',
              value: 0,
              show: true
            }, {
              title: 'Verduurzaming (zonnepanelen, isolatie e.d.)',
              value: 0,
              show: true
            }, {
              title: 'Geen specifiek spaardoel',
              value: 0,
              show: true
            }],
            error: null
          },
          savings: {
            title: 'Hoeveel spaargeld heeft u?',
            description: '',
            value: 0,
            type: 'amount',
            error: null
          },
          low_mortgage: {
            title: 'Welk bedrag aan spaargeld wilt u zelf inbrengen om het hypotheekbedrag zo laag mogelijk te houden?',
            value: -1,
            type: 'option',
            options: [
              {
                value: 0,
                label: 'Ik wil alleen het minimaal noodzakelijke bedrag aan eigen geld inbrengen',
                show: true
              },
              { value: 1, label: 'Anders, namelijk:', show: true }
            ],
            error: null,
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string',
              error: null
            }
          },
          loans_and_liabilities: {
            title: 'Heeft u op dit moment één of meerdere van de volgende leningen of verplichtingen?',
            required: 1,
            type: 'multiplechoice',
            questions: [{
              title: 'Betaalrekening met roodstandmogelijkheid',
              value: 0,
              show: true
            }, {
              title: 'Doorlopend krediet',
              value: 0,
              show: true
            }, {
              title: 'Persoonlijke lening',
              value: 0,
              show: true
            }, {
              title: 'Studieschuld',
              value: 0,
              show: true
            }, {
              title: 'Studievoorschot (vanaf 1 september 2019)',
              value: 0,
              show: true
            }, {
              title: 'Alimentatie ex-partner',
              value: 0,
              show: true
            }, {
              title: 'Private lease',
              value: 0,
              show: true
            }, {
              title: 'Telefoonkrediet',
              value: 0,
              show: true
            }, {
              title: 'Geen leningen of verplichtingen',
              value: 0,
              show: true
            }],
            error: null
          },
          saving_money_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string',
            error: null
          }
        }
      },
      section_5: {
        title: 'Doelstelling',
        description: '',
        questions: {
          goal: {
            title: 'Welke van deze situaties is op u van toepassing?',
            value: -1,
            type: 'option',
            options: [
              { value: 0, label: 'Ik wil een bestaande woning kopen', show: true },
              { value: 1, label: 'Ik wil mijn huis verbouwen', show: true },
              { value: 2, label: 'Ik wil mijn hypotheek oversluiten', show: true },
              { value: 3, label: 'Ik wil mijn huidige situatie evalueren', show: true },
              { value: 4, label: 'Ik wil een nieuwbouwwoning kopen', show: true },
              {
                value: 5,
                label: 'Mijn partner en ik gaan uit elkaar en ik wil in de woning blijven wonen',
                show: true
              }
            ],
            error: null,
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string',
              error: null
            }
          }
        }
      },
      section_6: {
        title: 'Testamenten',
        description: '',
        questions: {
          have_will: {
            title: 'Heeft u een testament?',
            description: '',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          have_life_will: {
            title: 'Heeft u een levenstestament?',
            description: '',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          is_will_old: {
            title: 'Is uw testament ouder dan 5 jaar en/of opgesteld voor 2003?',
            description: '',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          have_will_advice: {
            title: 'Heeft u behoefte aan testamentadvies?',
            description: '',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          }
        }
      }
    }
  },
  sustainability: {
    title: 'Verduurzamen',
    description: 'Het verduurzamen van je woning brengt zowel op de korte als de lange termijn voordelen met zich mee. Doordat je woning hierdoor energiezuiniger wordt dalen de energielasten en is het mogelijk dat je woning meer waard wordt.',
    sections: {
      section_1: {
        title: 'Algemeen',
        description: 'In hoeverre bent u bekend met de mogelijkheden die er zijn om uw (toekomstige) woning te verduurzamen?',
        questions: {
          energy_saving_measures: {
            title: 'Energiebesparende maatregelen:',
            description: '',
            options: [
              { value: 0, label: 'Niet', show: true },
              { value: 1, label: 'Beperkt', show: true },
              { value: 2, label: 'Volledig', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          savings: {
            title: 'Besparingen:',
            description: '',
            options: [
              { value: 0, label: 'Niet', show: true },
              { value: 1, label: 'Beperkt', show: true },
              { value: 2, label: 'Volledig', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          },
          financing_sustainability: {
            title: 'Financieren van verduurzaming:',
            description: '',
            options: [
              { value: 0, label: 'Niet', show: true },
              { value: 1, label: 'Beperkt', show: true },
              { value: 2, label: 'Volledig', show: true }
            ],
            value: -1,
            type: 'option',
            error: null
          }
        },
        explanation: {
          title: 'Toelichting (optioneel)',
          value: '',
          type: 'string'
        }
      },
      section_2: {
        title: 'Verduurzamen van de (toekomstige) woning',
        description: '',
        questions: {
          interested_sustainability_housing: {
            title: 'Bent u geïnteresseerd in het verduurzamen van uw huidige of toekomstige woning?',
            value: -1,
            type: 'option',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            error: null
          },
          options_sustainability_housing_mapping: {
            title: 'Heeft u de opties voor het verduurzamen van uw (toekomstige) woning al weleens in kaart laten brengen?',
            value: -1,
            type: 'option',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            error: null
          },
          report_sustainability_housing: {
            title: 'Heeft u al een idee en/of rapport inzake het verduurzamen van uw woning?',
            description: '<div> Onze adviseur kan voor u een indicatieve berekening maken hoe u uw (toekomstige) woning kunt verduurzamen. U ontvangt een overzicht van suggesties die bij uw woning passen en inzicht in de gemiddelde kosten en opbrengsten. </div>',
            value: -1,
            type: 'option',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            error: null
          },
          discuss_with_advisor_sustainability_housing: {
            title: 'Wilt u verduurzamen met uw adviseur bespreken?',
            value: -1,
            type: 'option',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            error: null
          }
        },
        explanation: {
          title: 'Toelichting (optioneel)',
          value: '',
          type: 'string'
        }
      },
      section_3: {
        title: 'Motivatie verduurzamen',
        description: '',
        questions: {
          reason_for_sustainability: {
            title: 'Wat is voor u de belangrijkste reden om uw woning te verduurzamen (twee antwoorden mogelijk)',
            required: 1,
            type: 'multiplechoice',
            questions: [{
              title: 'Verlagen energierekening',
              value: 0,
              show: true
            }, {
              title: 'Rentekorting op de hypotheek',
              value: 0,
              show: true
            }, {
              title: 'Beter voor het milieu',
              value: 0,
              show: true
            }, {
              title: 'Wooncomfort',
              value: 0,
              show: true
            }, {
              title: 'Waardestijging / courantheid van de woning',
              value: 0,
              show: true
            }, {
              title: 'Voldoen aan de eisen van het klimaatakkoord',
              value: 0,
              show: true
            }],
            error: null
          }
        }
      },
      section_4: {
        title: 'Bekostigen verduurzamingsmaatregelen',
        description: '',
        questions: {
          financing_sustainability: {
            title: 'Hoe wilt u (indien van toepassing) de verduurzamingsmaatregelen financieren:',
            description: 'Ook is het mogelijk een <a href="https://www.warmtefonds.nl/particulieren?gclid=CjwKCAjwq5-WBhB7EiwAl-HEkgOUANa0oO8jrsdCOBISTrK19SndfvjyQJ0ZmgV3w8Dk9haH4YyfcRoCcRwQAvD_BwE" target="_blank" style="color: #1890ff !important">Energiebespaarlening</a> af te sluiten bij het Nationaal Warmtefonds of een <a href="https://www.svn.nl/duurzaamheidslening" target="_blank" style="color: #1890ff !important">Duurzaamheidslening</a> bij uw (toekomstige) gemeente',
            value: -1,
            type: 'option',
            options: [
              { value: 0, label: 'Uit eigen middelen (spaargeld)', show: true },
              {
                value: 1,
                label: 'Persoonlijke lening ',
                show: true
              },
              { value: 2, label: 'Hypotheek(verhoging)', show: true }
            ],
            error: null
          }
        },
        explanation: {
          title: 'Toelichting (optioneel)',
          value: '',
          type: 'string'
        }
      }
    }
  },
  financing: {
    title: 'Financiering',
    description: 'Wij willen graag weten welke eisen en voorwaarden u stelt aan een hypotheek. Zo kan onze adviseur op zoek naar de beste hypotheek voor uw situatie.',
    sections: {
      section_11: {
        title: '',
        description: '',
        questions: {
          mortgage_payments: {
            title: 'De hypotheeklasten kunnen gedurende de looptijd van de geldlening wijzigen. Wat heeft uw voorkeur?',
            value: -1,
            type: 'option',
            options: [
              {
                value: 0,
                label: 'Stijgende netto hypotheeklasten: ik begin met lage aanvanglasten.',
                show: true
              },
              {
                value: 1,
                label: 'Stabiele netto hypotheeklasten gedurende de looptijd van de hypotheek.',
                show: true
              },
              {
                value: 2,
                label: 'Dalende netto hypotheeklasten: ik begin met hoge aanvanglasten.',
                show: true
              }
            ],
            error: null,
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string',
              error: null
            }
          },
          mortgage_properties: {
            title: 'Welke voorwaarden vindt u belangrijk voor sluiten van een hypotheek? (meerdere keuzes mogelijk)',
            required: 1,
            type: 'multiplechoice',
            questions: [{
              title: 'Het duurt nog geruime tijd voordat ik de hypotheek nodig heb. De geldigheidsduur van mijn offerte moet in ieder geval langer dan drie maanden zijn.',
              value: 0,
              show: true
            }, {
              title: 'Als de hypotheekrente daalt, nadat ik de offerte heb ondertekend, wil ik toch die lagere rente.',
              value: 0,
              show: true
            }, {
              title: 'Ik wil de hypotheekrente, ook na het ondertekenen hiervan, kosteloos kunnen annuleren.',
              value: 0,
              show: true
            }, {
              title: 'Ik wil meer dan 10% (van het hypotheekbedrag) per jaar boetevrij kunnen aflossen.',
              value: 0,
              show: true
            }, {
              title: 'Ik wil mijn hypotheekrente mee kunnen verhuizen naar een volgende woning.',
              value: 0,
              show: true
            }, {
              title: 'Ik zoek een geldverstrekker die de hypotheekrente automatisch verlaagt, zodat ik (extra) heb afgelost.',
              value: 0,
              show: true
            }],
            error: null
          },
          mortgage_properties_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string',
            error: null
          },
          insurance: {
            title: 'Hoe lang wenst u minimaal de zekerheid dat de hypotheekrente niet gaat stijgen?',
            value: 1,
            type: 'option',
            options: [
              { value: 1, label: '1 jaar', show: true },
              { value: 5, label: '5 jaar', show: true },
              { value: 10, label: '10 jaar', show: true },
              { value: 15, label: '15 jaar', show: true },
              { value: 20, label: '20 jaar', show: true },
              { value: 30, label: '30 jaar', show: true }
            ],
            error: null
          }

        }
      }
    }
  },
  mortgage: {
    title: 'Hypotheek',
    description: 'De adviseur wil graag weten wat uw ervaring en kennis is m.b.t. het afsluiten van een hypotheek. Daar willen wij u graag een aantal vragen over stellen.',
    sections: {
      section_20: {
        title: 'Doorlooptijd hypotheek',
        description: 'Het tijdsbestek voor het volledige proces, van het opstarten van de hypotheekaanvraag tot het definitieve akkoord van de geldverstrekker, bedraagt doorgaans 6 tot 8 weken. Deze periode kan als stressvol worden ervaren. De duur van het proces kan echter worden verkort, afhankelijk van de keuze van de geldverstrekker.',
        questions: {
          question_1: {
            title: 'Uw voorkeur met betrekking tot de doorlooptijd:',
            value: 0,
            type: 'radio',
            error: null,
            options: [
              { value: 0, label: 'Ik ben tevreden met een doorlooptijd van 6 tot 8 weken.', show: true },
              {
                value: 1,
                label: 'Ik geef indien mogelijk de voorkeur aan een versnelde doorlooptijd om zo min mogelijk stress te ervaren en zo snel mogelijk tot een definitief akkoord te komen.',
                show: true
              }
            ]
          }
        },
        note: 'Nb. De adviseur vraagt mogelijk op voorhand nog aanvullende informatie op.'
      },
      section_21: {
        title: 'Hypotheek',
        description: 'Sinds 2013 gelden er nieuwe regels voor hypotheken. Daar willen wij u graag een aantal vragen over stellen. Weet u bij welke hypotheekvormen u nog recht heeft op hypotheekrenteaftrek?',
        questions: {
          question_1: {
            title: 'Aflossingsvrij',
            value: -1,
            type: 'radio',
            error: null,
            options: [
              { value: 1, label: 'Wel renteaftrek', show: false },
              { value: 0, label: 'Geen renteaftrek', show: false }
            ]
          },
          question_2: {
            title: 'Annuïteiten',
            value: -1,
            type: 'radio',
            error: null,
            options: [
              { value: 1, label: 'Wel renteaftrek', show: false },
              { value: 0, label: 'Geen renteaftrek', show: false }
            ]
          },
          question_3: {
            title: 'Banksparen',
            value: -1,
            type: 'radio',
            error: null,
            options: [
              { value: 1, label: 'Wel renteaftrek', show: false },
              { value: 0, label: 'Geen renteaftrek', show: false }
            ]
          },
          question_4: {
            title: 'Beleggen',
            value: -1,
            type: 'radio',
            error: null,
            options: [
              { value: 1, label: 'Wel renteaftrek', show: false },
              { value: 0, label: 'Geen renteaftrek', show: false }
            ]
          },
          question_5: {
            title: 'Leven',
            value: -1,
            type: 'radio',
            error: null,
            options: [
              { value: 1, label: 'Wel renteaftrek', show: false },
              { value: 0, label: 'Geen renteaftrek', show: false }
            ]
          },
          question_6: {
            title: 'Lineair',
            value: -1,
            type: 'radio',
            error: null,
            options: [
              { value: 1, label: 'Wel renteaftrek', show: false },
              { value: 0, label: 'Geen renteaftrek', show: false }
            ]
          },
          experience: {
            title: 'Welke ervaring heeft u met hypotheken?',
            value: -1,
            type: 'option',
            error: null,
            options: [
              {
                value: 0,
                label: 'Ik heb een hypotheek en ik weet goed welke verplichting(en) ik ben aangegaan.',
                show: true
              },
              {
                value: 1,
                label: 'Ik heb een hypotheek, maar ik weet niet zo goed welke verplichtingen(en) ik ben aangegaan.',
                show: true
              },
              {
                value: 2,
                label: 'Ik heb nu geen hypotheek, maar heb deze in het verleden wel gehad.',
                show: true
              },
              {
                value: 3,
                label: 'Ik heb geen ervaring met het afsluiten van een hypotheek.',
                show: true
              },
              { value: 4, label: 'Ik heb in mijn werk te maken met hypotheken.', show: true }
            ],
            explanation: {
              title: 'Toelichting (optioneel)',
              value: '',
              type: 'string'
            }
          },
          question_7: {
            title: 'Aflossingsvrij',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_8: {
            title: 'Annuïteiten',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_9: {
            title: 'Banksparen',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_10: {
            title: 'Beleggen',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_11: {
            title: 'Leven',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_12: {
            title: 'Lineair',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          mortgage_types_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string'
          },
          form_saving: {
            title: 'Heeft u één van deze hypotheekvormen of geldleningen? (Bank)spaar Beleggen Leven',
            value: -1,
            type: 'option',
            error: null,
            options: [{ value: 1, label: 'Ja', show: true }, { value: 0, label: 'Nee', show: true }]
          },
          continue_saving: {
            title: 'Wilt u deze dan voortzetten?',
            value: -1,
            type: 'option',
            error: null,
            options: [{ value: 1, label: 'Ja', show: true }, { value: 0, label: 'Nee', show: true }]
          },
          saving_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string'
          },
          question_13: {
            title: 'Overlijdensrisicoverzekering (ORV)',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_14: {
            title: 'Lijfrenteverzekering',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_15: {
            title: 'Arbeidsongeschiktheidsverzekering (AOV)',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_16: {
            title: 'Pensioenregeling',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_17: {
            title: 'Algemene ouderdomswet (AOW)',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_18: {
            title: 'Algemene nabestaandenwet (ANW)',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_19: {
            title: 'Wet werk en inkomen naar arbeidsvermogen (WIA)',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          },
          question_20: {
            title: 'Werkloosheidsuitkering (WW)',
            value: -1,
            type: 'radio',
            error: null,
            options: [{ value: 0, label: 'Niet', show: false }, {
              value: 1,
              label: 'Beperkt',
              show: false
            }, {
              value: 2,
              label: 'Volledig',
              show: false
            }]
          }
        }
      },
      section_22: {
        title: 'Hypotheekvoorwaarden',
        description: '',
        questions: {
          question_1: {
            title: 'Minimale geldigheidsduur hypotheekofferte in maanden',
            value: 3,
            type: 'option',
            error: null,
            options: [
              { value: 3, label: '3 maanden', show: true },
              { value: 6, label: '6 maanden', show: true },
              { value: 9, label: '9 maanden', show: true },
              { value: 12, label: '12 maanden', show: true }
            ]
          },
          question_2: {
            title: 'Vaststelling hypotheekrente bij het passeren van de hypotheekakte',
            value: 0,
            type: 'option',
            error: null,
            options: [
              {
                value: 0,
                label: 'Rentetarief zoals vastgesteld bij het uitbrengen van het renteaanbod',
                show: true
              },
              {
                value: 1,
                label: 'Rentetarief zoals die van toepassing is op het moment van passeren van de hypotheekakte',
                show: true
              },
              {
                value: 2,
                label: 'Het laagste rentetarief tussen het moment van uitbrengen renteaanbod en het moment van passeren van de hypotheekakte',
                show: true
              }
            ]
          },
          question_3: {
            title: 'Geen annuleringskosten na het ondertekenen offerte',
            value: 0,
            type: 'option',
            error: null,
            options: [
              { value: 0, label: 'Eis', show: true },
              { value: 1, label: 'Wens', show: true }
            ]
          },
          question_4: {
            title: 'Jaarlijks boetevrij (extra) af kunnen lossen',
            value: 0,
            type: 'option',
            error: null,
            options: [
              { value: 0, label: '10%', show: true },
              { value: 1, label: '25%', show: true },
              { value: 2, label: '20%', show: true },
              { value: 3, label: 'onbeperkt', show: true }
            ]
          },
          question_5: {
            title: 'Nationale Hypotheek Garantie gewenst',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ]
          },
          question_6: {
            title: 'Jaarlijks boetevrij (extra) af kunnen lossen',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ]
          },
          question_7: {
            title: 'Lager rentepercentage als gevolg van (extra) aflossingen tijdens de rentevasteperiode',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ]
          },
          question_8: {
            title: 'Hypotheekrente desgewenst meeverhuizen naar volgende woning',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ]
          },
          question_9: {
            title: 'Overlijdensrisicoverzekering verplicht',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ]
          },
          explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string'
          }
        }
      }
    }
  },
  moving: {
    title: 'Opnieuw verhuizen',
    description: 'Het kan zijn dat u nu al weet dat u over een aantal jaar weer gaat verhuizen. Dit is belangrijk om te weten om u nu al van het juiste advies te kunnen voorzien.',
    sections: {
      section_31: {
        title: 'Verhuizen',
        description: '',
        questions: {
          i_expect_to_move: {
            title: 'Ik ben van plan op termijn te gaan verhuizen',
            value: -1,
            type: 'option',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            error: null
          },
          moving_in_years: {
            title: 'De kans bestaat dat ik verhuis binnen...',
            value: 'Maak een keuze',
            type: 'option',
            options: [
              { value: 0, label: '0 jaar', show: true },
              { value: 10, label: '10 jaar', show: true },
              { value: 20, label: '20 jaar', show: true },
              { value: 30, label: '30 jaar', show: true }
            ],
            error: null
          }
        }
      },
      section_32: {
        title: 'Woonwens',
        description: '',
        questions: {
          jumba: {
            title: 'Het huis waarin ik zou willen wonen',
            description: '',
            type: 'jumba',
            search_postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            jumba_id: {
              title: 'Gewenste woning',
              value: '',
              type: 'string',
              error: null
            },
            search_radius: {
              title: 'Straal',
              value: 1,
              type: 'number',
              options: radiusOptions,
              error: null
            },
            postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            city: {
              title: 'Plaats',
              value: '',
              type: 'string',
              error: null
            },
            street: {
              title: 'Straatnaam',
              value: '',
              type: 'string',
              error: null
            },
            number: {
              title: 'Nummer',
              value: '',
              type: 'string',
              error: null
            },
            number_addition: {
              title: 'Toevoeging',
              value: '',
              type: 'string',
              error: null
            },
            value_low: {
              title: 'Waarde (laag)',
              value: 0,
              type: 'number',
              error: null
            },
            value_high: {
              title: 'Waarde (hoog)',
              value: 0,
              type: 'number',
              error: null
            },
            home_type: {
              title: 'Soort woning',
              value: 'eengezinswoning',
              type: 'option',
              options: homeTypeOptions,
              error: null
            }
          },
          solution: {
            title: 'Naar welke oplossing gaat uw voorkeur uit?',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 0, label: 'Spaargeld', show: true },
              { value: 1, label: 'Verhuizen naar een andere woonplaats.', show: true },
              { value: 2, label: 'Versneld aflossen', show: true },
              { value: 3, label: 'Meer werken', show: true },
              { value: 4, label: 'Minder werken', show: true },
              { value: 5, label: 'Anders namelijk:', show: true }
            ],
            explanation: {
              title: 'Toelichting',
              value: '',
              type: 'string',
              error: null
            }
          },
          advise: {
            title: 'Ik wil dit graag bespreken met de hypotheekadviseur',
            value: -1,
            type: 'option',
            options: [
              { value: 1, label: 'Ja', show: true },
              { value: 0, label: 'Nee', show: true }
            ],
            error: null
          }
        }
      }
    }
  },
  children: {
    title: 'Kinderen',
    description: 'Gezinsuitbreidingen of juist kinderen die uit huis gaan, kunnen veel effect hebben op de woonbehoefte en de kosten die hiermee gemoeid zijn.',
    sections: {
      section_41: {
        title: 'Kinderen',
        description: '',
        questions: {
          hours: {
            title: 'Mocht het voorkomen dat u meer (thuiswonende) kinderen krijgt, hoeveel uur zou u dan willen werken?',
            value: 40,
            type: 'number',
            error: null
          },
          family_expansion: {
            title: 'Verwacht u binnenkort een gezinsuitbreiding?',
            value: 0,
            type: 'option',
            options: [
              { value: '1', label: 'Ja', show: true },
              { value: '0', label: 'Nee', show: true }
            ],
            error: null
          }
        }
      },
      section_42: {
        title: 'Woonwens',
        description: '',
        questions: {
          moving: {
            title: 'Verwacht u te willen verhuizen in het geval van een gezinsuitbreiding?',
            value: -1,
            type: 'option',
            options: [
              { value: '1', label: 'Ja', show: true },
              { value: '0', label: 'Nee', show: true }
            ],
            error: null
          },
          jumba: {
            title: 'Het huis waarin ik zou willen wonen',
            description: '',
            type: 'jumba',
            search_postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            jumba_id: {
              title: 'Gewenste woning',
              value: '',
              type: 'string',
              error: null
            },
            search_radius: {
              title: 'Straal',
              value: 1,
              type: 'number',
              options: radiusOptions,
              error: null
            },
            postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            city: {
              title: 'Plaats',
              value: '',
              type: 'string',
              error: null
            },
            street: {
              title: 'Straatnaam',
              value: '',
              type: 'string',
              error: null
            },
            number: {
              title: 'Nummer',
              value: '',
              type: 'string',
              error: null
            },
            number_addition: {
              title: 'Toevoeging',
              value: '',
              type: 'string',
              error: null
            },
            value_low: {
              title: 'Waarde (laag)',
              value: 0,
              type: 'number',
              error: null
            },
            value_high: {
              title: 'Waarde (hoog)',
              value: 0,
              type: 'number',
              error: null
            },
            home_type: {
              title: 'Soort woning',
              value: 'eengezinswoning',
              type: 'option',
              options: homeTypeOptions,
              error: null
            }
          },
          financing: {
            title: 'Naar welke oplossing gaat uw voorkeur uit?',
            value: -1,
            type: 'option',
            options: [
              { value: 2, label: 'Versneld de hypotheek aflossen', show: true },
              { value: 3, label: 'Spaargeld gebruiken', show: true },
              { value: 5, label: 'Anders namelijk:', show: true }
            ],
            error: null,
            explanation: {
              title: 'Toelichting',
              value: '',
              type: 'string'
            }
          }
        }
      }
    }
  },
  unemployment: {
    title: 'Werkloosheid',
    description: '',
    sections: {
      section_51: {
        title: 'Werkloosheid',
        description: 'Wanneer u werkloos raakt, heeft dit vaak grote financiële consequenties. Het is daarom belangrijk om uw financiële situatie inzichtelijk te maken. Door vooraf goed bij de consequentie stil te staan, kunt u problemen voorkomen.',
        questions: {
          current_home: {
            title: 'Wat is uw doelstelling indien u of uw partner tijdens de looptijd van de hypotheek werkloos wordt?',
            value: -1,
            type: 'option',
            options: [
              {
                value: 1,
                label: 'Ik wil in de woning kunnen blijven wonen met het huidige (gezamenlijke) netto maandinkomen',
                show: true
              },
              { value: 0, label: 'Ik wil de woning zo snel mogelijk verkopen.', show: true }
            ],
            error: null
          },
          income: {
            title: 'Met een netto maandinkomen van:',
            value: 0,
            type: 'amount',
            error: null
          },
          percentage: {
            title: 'of met een netto maandinkomen van:',
            value: 0,
            type: 'amount',
            error: null
          },
          becoming_unemployed: {
            title: 'Stel: U raakt werkloos tijdens de looptijd van de hypotheek. Hoe kunt u of gaat u dit verlies aan inkomen opvangen? (meerdere keuzes mogelijk)',
            type: 'multiplechoice',
            required: 1,
            questions: [{
              title: 'Mijn partner gaat meer werken om het inkomen aan te vullen.',
              value: 0,
              show: true
            }, {
              title: 'Ik ga mijn spaargeld gebruiken om mijn inkomen maandelijks aan te vullen.',
              value: 0,
              show: true
            }, {
              title: 'Ik wil de woning zo snel mogelijk kunnen verkopen.',
              value: 0,
              show: true
            }, {
              title: 'Ik heb hiervoor in het verleden een (woonlasten)verzekering afgesloten.',
              value: 0,
              show: true
            }, {
              title: 'Ik ga mijn consumptieve uitgaven verlagen.',
              value: 0,
              show: true
            }, {
              title: 'Ik kan dit zelf niet (volledig) opvangen en wil kijken of hiervoor een verzekeringsoplossing is.',
              value: 0,
              show: true
            }, {
              title: 'Ik heb hier geen voorziening voor en wil dit ook niet regelen.',
              value: 0,
              show: true
            }],
            error: null
          },
          becoming_unemployed_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string',
            error: null
          },
          jumba: {
            title: 'Het huis waarin ik zou willen wonen',
            description: '',
            type: 'jumba',
            search_postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            jumba_id: {
              title: 'Gewenste woning',
              value: '',
              type: 'string',
              error: null
            },
            search_radius: {
              title: 'Straal',
              value: 1,
              type: 'number',
              options: radiusOptions,
              error: null
            },
            postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            city: {
              title: 'Plaats',
              value: '',
              type: 'string',
              error: null
            },
            street: {
              title: 'Straatnaam',
              value: '',
              type: 'string',
              error: null
            },
            number: {
              title: 'Nummer',
              value: '',
              type: 'string',
              error: null
            },
            number_addition: {
              title: 'Toevoeging',
              value: '',
              type: 'string',
              error: null
            },
            value_low: {
              title: 'Waarde (laag)',
              value: 0,
              type: 'number',
              error: null
            },
            value_high: {
              title: 'Waarde (hoog)',
              value: 0,
              type: 'number',
              error: null
            },
            home_type: {
              title: 'Soort woning',
              value: 'eengezinswoning',
              type: 'option',
              options: homeTypeOptions,
              error: null
            }
          },
          solution: {
            title: 'Naar welke oplossing gaat uw voorkeur uit?',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 0, label: 'Mijn partner gaat meer werken', show: true },
              { value: 1, label: 'Verhuizen (andere stad of dorp)', show: true },
              { value: 2, label: 'Kleiner wonen', show: true },
              { value: 3, label: 'Spaargeld gebruiken', show: true },
              { value: 4, label: 'Anders namelijk:', show: true }
            ],
            explanation: {
              title: 'Toelichting',
              value: '',
              type: 'string'
            }
          }
        }
      },
      section_52: {
        title: 'Werkloosheid van partner',
        description: '',
        questions: {
          partner_working_time: {
            title: 'Mocht het voorkomen dat mijn partner 100% werkloos wordt, dan is het aantal uur dat ik zou willen werken:',
            value: 0,
            type: 'number',
            error: null
          }
        }
      }
    }
  },
  incapacity_work: {
    title: 'Arbeidsongeschiktheid',
    description: 'Wanneer u arbeidsongeschikt raakt, heeft dit vaak grote financiële consequenties. Door vooraf goed bij deze consequenties stil te staan, kunt u problemen voorkomen.',
    sections: {
      section_61: {
        title: 'Arbeidsongeschiktheid',
        description: '',
        questions: {
          current_home: {
            title: 'Wat is uw doelstelling indien u of uw partner tijdens de looptijd van de hypotheek arbeidsongeschikt wordt?',
            value: -1,
            type: 'option',
            options: [
              {
                value: 1,
                label: 'Ik wil in de woning kunnen blijven wonen met het huidige (gezamenlijke) netto maandinkomen',
                show: true
              },
              { value: 0, label: 'Ik wil de woning zo snel mogelijk verkopen.', show: true }
            ],
            error: null
          },
          jumba: {
            title: 'Het huis waarin ik zou willen wonen',
            description: '',
            type: 'jumba',
            search_postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            jumba_id: {
              title: 'Gewenste woning',
              value: '',
              type: 'string',
              error: null
            },
            search_radius: {
              title: 'Straal',
              value: 1,
              type: 'number',
              options: radiusOptions,
              error: null
            },
            postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            city: {
              title: 'Plaats',
              value: '',
              type: 'string',
              error: null
            },
            street: {
              title: 'Straatnaam',
              value: '',
              type: 'string',
              error: null
            },
            number: {
              title: 'Nummer',
              value: '',
              type: 'string',
              error: null
            },
            number_addition: {
              title: 'Toevoeging',
              value: '',
              type: 'string',
              error: null
            },
            value_low: {
              title: 'Waarde (laag)',
              value: 0,
              type: 'number',
              error: null
            },
            value_high: {
              title: 'Waarde (hoog)',
              value: 0,
              type: 'number',
              error: null
            },
            home_type: {
              title: 'Soort woning',
              value: 'eengezinswoning',
              type: 'option',
              options: homeTypeOptions,
              error: null
            }
          },
          income: {
            title: 'Met een netto maandinkomen van:',
            value: 0,
            type: 'amount'
          },
          percentage: {
            title: 'of met een netto maandinkomen van:',
            value: 0,
            type: 'amount'
          },
          becoming_incapacitated: {
            title: 'Stel u raakt arbeidsongeschikt tijdens de looptijd van de hypotheek. Hoe kunt of gaat u dit verlies aan inkomen opvangen?',
            type: 'multiplechoice',
            required: 1,
            questions: [{
              title: 'Mijn partner gaat meer werken om het inkomen aan te vullen.',
              value: 0,
              show: true
            }, {
              title: 'Ik ga mijn spaargeld gebruiken om mijn inkomen maandelijks aan te vullen.',
              value: 0,
              show: true
            }, {
              title: 'Ik heb hiervoor in het verleden (via de werkgever) een verzekering afgesloten en wil kijken of deze nog toereikend is.',
              value: 0,
              show: true
            }, {
              title: 'Ik ga mijn (consumptieve) uitgaven verlagen.',
              value: 0,
              show: true
            }, {
              title: 'Ik kan dit zelf niet (volledig) opvangen en wil kijken of hiervoor een verzekeringsoplossing is.',
              value: 0,
              show: true
            }, {
              title: 'Ik heb hier geen voorziening voor en wil dit ook niet regelen. Ik weet dat ik het risico loop dat ik de hypotheeklasten dan niet meer kan betalen, het moet verkopen en een eventuele restschuld overhoud.',
              value: 0,
              show: true
            }, {
              title: 'Ik wil dat de hypotheek nog passend is bij het inkomen van mij en mijn partner.',
              value: 0,
              show: true
            }, {
              title: 'Ik wil de woning zo snel mogelijk kunnen verkopen.',
              value: 0,
              show: true
            }],
            error: null
          },
          becoming_incapacitated_explanation: {
            title: 'Toelichting (Optioneel)',
            value: '',
            type: 'string',
            error: null
          },
          insurance_type: {
            title: 'Weet u welke voorziening uw werkgever (zie loonstrookje) voor u geregeld heeft, indien u arbeidsongeschikt raakt?',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 0, label: 'WGA-hiaat Basis', show: true },
              { value: 1, label: 'WGA-hiaat Uitgebreid', show: true },
              { value: 2, label: 'WIA-excedent', show: true },
              { value: 3, label: 'WIA-inkomensaanvullend', show: true },
              { value: 4, label: 'Geen', show: true }
            ],
            explanation: {
              title: 'Toelichting (Optioneel)',
              value: '',
              type: 'string',
              error: null
            }
          },
          solution: {
            title: 'Naar welke oplossing gaat uw voorkeur uit?',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 1, label: 'Verhuizen (andere stad of dorp)', show: true },
              { value: 2, label: 'Kleiner wonen', show: true },
              { value: 3, label: 'Spaargeld gebruiken', show: true },
              { value: 4, label: 'Anders namelijk:', show: true }
            ],
            explanation: {
              title: 'Toelichting',
              value: '',
              type: 'string',
              error: null
            }
          }
        }
      },
      section_62: {
        title: 'Arbeidsongeschiktheid partner',
        description: '',
        questions: {
          incapacity_work_hours: {
            title: 'Mocht het voorkomen dat mijn partner 100% arbeidsongeschikt wordt, dan is het aantal uur dat ik zou willen werken:',
            value: 0,
            type: 'number',
            error: null
          }
        }
      }
    }
  },
  death_of_partner: {
    title: 'Overlijden van partner',
    description: 'Op het moment dat u partner komt te overlijden, krijgt u te maken met een ander inkomen. Dit kan van invloed zijn op uw financiële mogelijkheden. Het is daarom belangrijk om de risico’s en gevolgen van overlijden goed in te schatten.',
    sections: {
      section_71: {
        title: 'Overlijden van partner',
        description: 'Wat is uw doelstelling indien u of uw partner tijdens de looptijd van de hypotheek komt te overlijden?',
        questions: {
          working_time: {
            title: 'Mocht het voorkomen dat mijn partner komt te overlijden, dan is het aantal uur dat ik zou willen werken:',
            value: 0,
            type: 'number',
            error: null
          },
          jumba: {
            title: 'Het huis waarin ik zou willen wonen',
            description: '',
            type: 'jumba',
            search_postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            jumba_id: {
              title: 'Gewenste woning',
              value: '',
              type: 'string',
              error: null
            },
            search_radius: {
              title: 'Straal',
              value: 1,
              type: 'number',
              options: radiusOptions,
              error: null
            },
            postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            city: {
              title: 'Plaats',
              value: '',
              type: 'string',
              error: null
            },
            street: {
              title: 'Straatnaam',
              value: '',
              type: 'string',
              error: null
            },
            number: {
              title: 'Nummer',
              value: '',
              type: 'string',
              error: null
            },
            number_addition: {
              title: 'Toevoeging',
              value: '',
              type: 'string',
              error: null
            },
            value_low: {
              title: 'Waarde (laag)',
              value: 0,
              type: 'number',
              error: null
            },
            value_high: {
              title: 'Waarde (hoog)',
              value: 0,
              type: 'number',
              error: null
            },
            home_type: {
              title: 'Soort woning',
              value: 'eengezinswoning',
              type: 'option',
              options: homeTypeOptions,
              error: null
            }
          },
          solution: {
            title: 'Naar welke oplossing gaat uw voorkeur uit?',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 0, label: 'Meer werken', show: true },
              { value: 1, label: 'Verhuizen (andere stad of dorp)', show: true },
              { value: 2, label: 'Kleiner wonen', show: true },
              { value: 3, label: 'Spaargeld gebruiken', show: true },
              { value: 4, label: 'Anders namelijk:', show: true }
            ],
            explanation: {
              title: 'Toelichting',
              value: '',
              type: 'string',
              error: null
            }
          },
          current_home: {
            title: 'Wat is uw doelstelling indien u of uw partner tijdens de looptijd van de hypotheek komt te overlijden?',
            value: -1,
            type: 'option',
            options: [
              {
                value: 1,
                label: 'Ik wil in de woning kunnen blijven wonen met het huidige (gezamenlijke) netto maandinkomen',
                show: true
              },
              { value: 0, label: 'Ik wil de woning zo snel mogelijk verkopen.', show: true }
            ],
            error: null
          },
          my_partner_is_going_to_die: {
            title: 'Bij overlijden is het mogelijk dat u niet meer kunt blijven wonen. Wat vindt u belangrijk?',
            type: 'multiplechoice',
            required: 1,
            questions: [{
              title: 'Ik wil de woning zo snel mogelijk kunnen verkopen.',
              value: 0,
              show: true
            }, {
              title: 'Ik wil dat de hypotheek nog passend is bij het inkomen van mij en mijn partner.',
              value: 0,
              show: true
            }, {
              title: 'Anders: (Noteer bij de toelichting)',
              value: 0,
              show: true
            }],
            error: null
          },
          other_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string',
            error: null
          },
          partner_dies: {
            title: 'Stel: Uw partner komt tijdens de looptijd van de hypotheek te overlijden. Dan krijgt u te  maken met een daling in het netto maandinkomen, hetgeen tot gevolg kan hebben dat uw hypotheeklasten en levensstijl niet meer betaalbaar zijn. Hoe kunt of gaat u dit opvangen? (meerdere keuzes mogelijk)',
            type: 'multiplechoice',
            required: 1,
            questions: [{
              title: 'Ik ga meer werken om het inkomen aan te vullen.',
              value: 0,
              show: true
            }, {
              title: 'Ik ga mijn spaargeld gebruiken om mijn inkomen maandelijks aan te vullen.',
              value: 0,
              show: true
            }, {
              title: 'Ik heb hiervoor in het verleden (via de werkgever) een verzekering afgesloten en wil kijken of deze nog toereikend is.',
              value: 0,
              show: true
            }, {
              title: 'Ik ga mijn (consumptieve) uitgaven verlagen.',
              value: 0,
              show: true
            }, {
              title: 'Ik kan dit zelf niet (volledig) opvangen en wil kijken of hiervoor een verzekeringsoplossing is.',
              value: 0,
              show: true
            }, {
              title: 'Ik heb hier geen voorziening voor en wil dit ook niet regelen. Ik weet dat ik het risico loop dat ik de hypotheeklasten dan niet meer kan betalen, het moet verkopen en een eventuele restschuld overhoud.',
              value: 0,
              show: true
            }],
            error: null
          },
          no_insurance_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string',
            error: null
          }
        }
      }
    }
  },
  divorce: {
    title: 'Uit elkaar / scheiden',
    description: 'Op het moment dat u uit elkaar gaat, krijgt u te maken met een ander inkomen. Dit kan van invloed zijn op uw financiële mogelijkheden. Het is daarom belangrijk om de risico’s en gevolgen van uit elkaar gaan goed in te schatten.',
    sections: {
      section_81: {
        title: 'Uit elkaar / scheiden',
        description: 'Wat is uw doelstelling indien u of uw partner tijdens de looptijd van de hypotheek uit elkaar gaan?',
        questions: {
          current_home: {
            title: 'Wat is uw doelstelling indien u of uw partner tijdens de looptijd van de hypotheek uit elkaar gaan?',
            value: -1,
            type: 'option',

            options: [
              {
                value: 1,
                label: 'Ik wil in de woning kunnen blijven wonen met het huidige (gezamenlijke) netto maandinkomen',
                show: true
              },
              { value: 0, label: 'Ik wil de woning zo snel mogelijk verkopen.', show: true }
            ],
            error: null
          },
          working_time: {
            title: 'Mocht het voorkomen dat mijn partner en ik uit elkaar gaan/gaan scheiden, dan is het aantal uur dat ik zou willen werken:',
            value: 0,
            type: 'number',
            error: null
          },
          jumba: {
            title: 'Het huis waarin ik zou willen wonen',
            description: '',
            type: 'jumba',
            search_postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            jumba_id: {
              title: 'Gewenste woning',
              value: '',
              type: 'string',
              error: null
            },
            search_radius: {
              title: 'Straal',
              value: 1,
              type: 'number',
              options: radiusOptions,
              error: null
            },
            postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            city: {
              title: 'Plaats',
              value: '',
              type: 'string',
              error: null
            },
            street: {
              title: 'Straatnaam',
              value: '',
              type: 'string',
              error: null
            },
            number: {
              title: 'Nummer',
              value: '',
              type: 'string',
              error: null
            },
            number_addition: {
              title: 'Toevoeging',
              value: '',
              type: 'string',
              error: null
            },
            value_low: {
              title: 'Waarde (laag)',
              value: 0,
              type: 'number',
              error: null
            },
            value_high: {
              title: 'Waarde (hoog)',
              value: 0,
              type: 'number',
              error: null
            },
            home_type: {
              title: 'Soort woning',
              value: 'eengezinswoning',
              type: 'option',
              options: homeTypeOptions,
              error: null
            }
          }
        }
      }
    }
  },
  pension: {
    title: 'Pensioen',
    description: 'Op het moment dat u met pensioen gaat, krijgt u te maken met een ander inkomen. Dit kan van invloed zijn op uw financiële mogelijkheden. Het is daarom belangrijk om de risico’s en gevolgen van uw pensioen goed in te schatten.',
    sections: {
      section_91: {
        title: 'Pensioen',
        description: 'Wat doet u als u of uw partner tijdens de looptijd van uw hypotheek met pensioen gaat?',
        questions: {
          current_home: {
            title: 'Wat is uw doelstelling indien u of uw partner tijdens de looptijd van de hypotheek met pensioen gaat?',
            value: -1,
            type: 'option',
            error: null,
            options: [
              {
                value: 1,
                label: 'Ik wil in de woning kunnen blijven wonen met het huidige (gezamenlijke) netto maandinkomen',
                show: true
              },
              { value: 0, label: 'Ik wil de woning zo snel mogelijk verkopen.', show: true }
            ]
          },
          income: {
            title: 'Met een netto maandinkomen van:',
            value: 0,
            type: 'amount'
          },
          percentage: {
            title: 'of met een netto maandinkomen van:',
            value: 0,
            type: 'amount'
          },
          sell_the_house: {
            title: 'Ik wil de woning zo snel mogelijk kunnen verkopen.',
            value: 0,
            type: 'bool'
          },
          change_mortgage: {
            title: 'Ik wil dat de hypotheek nog passend is bij het inkomen mijn de partner.',
            value: 0,
            type: 'bool'
          },
          other: {
            title: 'Anders: (Noteer bij de toelichting)',
            value: 0,
            type: 'bool'
          },
          other_explanation: {
            title: 'Toelichting',
            value: '',
            type: 'string'
          },
          moving: {
            title: 'Verhuizen naar volgende woning',
            value: -1,
            type: 'radio',
            error: null,
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ]
          },
          heritage: {
            title: 'Een erfenis/schenking',
            value: -1,
            type: 'radio',
            error: null,
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ]
          },
          relevant_change: {
            title: 'Andere relevante verandering',
            error: null,
            value: -1,
            type: 'radio',
            options: [
              { value: 0, label: 'Onwaarschijnlijk', show: false },
              { value: 1, label: 'Kans is aanwezig', show: false },
              { value: 2, label: 'Zeer waarschijnlijk', show: false }
            ]
          },
          changes_financial_state_explanation: {
            title: 'Toelichting (Optioneel)',
            value: '',
            type: 'string'
          },
          partner_dies: {
            title: 'Stel: Uw partner komt te overlijden als u met pensioen bent. Dan krijgt u te maken met een daling in het netto maandinkomen, hetgeen tot gevolg kan hebben dat uw hypotheeklasten en levensstijl niet meer betaalbaar zijn. Hoe kunt of gaat u dit opvangen? (meerdere keuzes mogelijk)',
            type: 'multiplechoice',
            required: 1,
            questions: [{
              title: 'Ik ga mijn spaargeld gebruiken om mijn inkomen maandelijks aan te vullen.',
              value: 0,
              show: true
            }, {
              title: 'Ik ga mijn (consumptieve) uitgaven verlagen.',
              value: 0,
              show: true
            }, {
              title: 'Ik kan dit zelf niet (volledig) opvangen en wil kijken of hiervoor een verzekeringsoplossing is.',
              value: 0,
              show: true
            }, {
              title: 'Ik heb hier geen voorziening voor en wil dit ook niet regelen. Ik weet dat ik het risico loop dat ik de hypotheeklasten dan niet meer kan betalen, het moet verkopen en een eventuele restschuld overhoud.',
              value: 0,
              show: true
            }],
            error: null
          },
          no_insurance_explanation: {
            title: 'Toelichting (optioneel)',
            value: '',
            type: 'string',
            error: null
          },
          jumba: {
            title: 'Het huis waarin ik zou willen wonen',
            description: '',
            type: 'jumba',
            search_postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            jumba_id: {
              title: 'Gewenste woning',
              value: '',
              type: 'string',
              error: null
            },
            search_radius: {
              title: 'Straal',
              value: 1,
              type: 'number',
              options: radiusOptions,
              error: null
            },
            postal_code: {
              title: 'Postcode',
              value: '',
              type: 'string',
              error: null
            },
            city: {
              title: 'Plaats',
              value: '',
              type: 'string',
              error: null
            },
            street: {
              title: 'Straatnaam',
              value: '',
              type: 'string',
              error: null
            },
            number: {
              title: 'Nummer',
              value: '',
              type: 'string',
              error: null
            },
            number_addition: {
              title: 'Toevoeging',
              value: '',
              type: 'string',
              error: null
            },
            value_low: {
              title: 'Waarde (laag)',
              value: 0,
              type: 'number',
              error: null
            },
            value_high: {
              title: 'Waarde (hoog)',
              value: 0,
              type: 'number',
              error: null
            },
            home_type: {
              title: 'Soort woning',
              value: 'eengezinswoning',
              type: 'option',
              options: homeTypeOptions,
              error: null
            }
          }
        }
      },
      section_92: {
        title: 'Pensionering van Partner',
        description: 'Wat is uw doelstelling indien u of uw partner tijdens de looptijd van de hypotheek met pensioen gaan?',
        questions: {
          working_time: {
            title: 'Als mijn partner met pensioen gaat, dan is het aantal uur dat ik zou willen werken:',
            value: 0,
            type: 'number',
            error: null
          },
          solution: {
            title: 'Naar welke oplossing gaat uw voorkeur uit?',
            value: -1,
            type: 'option',
            error: null,
            options: [
              { value: 0, label: 'Meer werken', show: true },
              { value: 1, label: 'Verhuizen (andere stad of dorp)', show: true },
              { value: 2, label: 'Kleiner wonen', show: true },
              { value: 3, label: 'Spaargeld gebruiken', show: true },
              { value: 4, label: 'Anders namelijk:', show: true }
            ],
            explanation: {
              title: 'Toelichting',
              value: '',
              type: 'string'
            }
          }
        }
      }
    }
  },
  questions_for_the_advisor: {
    title: 'Vragen voor de adviseur',
    description: 'Ik wil graag meer weten. Kies het onderwerp en stel uw vraag.',
    sections: {
      section_101: {
        questions: {
          question_1: {
            title: 'Arbeidsongeschiktheid',
            value: 0,
            type: 'bool',
            explanation: {
              title: 'Stel uw vraag',
              value: '',
              type: 'string'
            }
          },
          question_2: {
            title: 'Pensioen',
            value: 0,
            type: 'bool',
            explanation: {
              title: 'Stel uw vraag',
              value: '',
              type: 'string'
            }
          },
          question_3: {
            title: 'Uit elkaar / scheiden',
            value: 0,
            type: 'bool',
            explanation: {
              title: 'Stel uw vraag',
              value: '',
              type: 'string'
            }
          },
          question_4: {
            title: 'Overlijden',
            value: 0,
            type: 'bool',
            explanation: {
              title: 'Stel uw vraag',
              value: '',
              type: 'string'
            }
          },
          question_5: {
            title: 'Kinderen',
            value: 0,
            type: 'bool',
            explanation: {
              title: 'Stel uw vraag',
              value: '',
              type: 'string'
            }
          },
          question_6: {
            title: 'Werkloosheid',
            value: 0,
            type: 'bool',
            explanation: {
              title: 'Stel uw vraag',
              value: '',
              type: 'string'
            }
          },
          question_7: {
            title: 'Verduurzaming',
            value: 0,
            type: 'bool',
            explanation: {
              title: 'Stel uw vraag',
              value: '',
              type: 'string'
            }
          }
        }
      }
    }
  }
}

const state = survey && survey.status && survey.status.version === initialState.status.version ? survey : initialState

if (survey?.customer?.invitation?.type?.value) {
  Sentry.setTag('invitation_type', survey.customer.invitation.type.value)
}

// assign sustainability to state if it does not exist
if (!state.sustainability) state[SUSTAINABILITY] = initialState.sustainability

// const state = initialState // override for testing purposes
console.log('Version:', survey && survey.status ? survey.status.version : -1, initialState.status.version)

function commitCustomer (commit, data) {
  // Invitation
  if (Object.keys(data.invitation.data).length > 0) {
    commit('addInvitation', data.invitation.data)
  } else {
    Sentry.captureException(new Error('Geen geldige uitnodiging'))
    commit('setHasValidInvitation', false)
  }

  // Address
  if (Object.keys(data.address.data).length > 0) {
    commit('addAddress', data.address.data)
  }

  // Partner
  if (Object.keys(data.partner.data).length > 0) {
    commit('addPartner', data.partner.data)
    commit('submit', { name: 'customer.has_partner.value', value: 1 })
    if (Object.keys(data.partner.data.address).length > 0) {
      commit('addPartnerAddress', data.partner.data.address.data)
    }
  }

  // Office
  if (Object.keys(data.office.data).length > 0) {
    commit('addOffice', data.office.data)
    if (data.office.data.style) {
      if (Object.keys(data.office.data.style).length > 0) {
        commit('addOfficeStyle', data.office.data.style)
      }
    }
  }

  // Children
  if (data.children && data.children.data.length > 0) {
    data.children.data.forEach(child => {
      commit('addChild', {
        id: {
          title: 'id',
          description: '',
          value: child.id,
          type: 'number',
          error: null
        },
        first_name: {
          title: 'Roepnaam',
          description: '',
          value: child.first_name,
          type: 'text',
          error: null
        },
        insertion: {
          title: 'Tussenvoegsel',
          description: '',
          value: '',
          type: 'text',
          error: null
        },
        last_name: {
          title: 'Achternaam',
          description: '',
          value: child.last_name,
          type: 'text',
          error: null
        },
        place_of_birth: {
          title: 'Geboorteplaats',
          description: '',
          value: child.place_of_birth,
          type: 'text',
          error: null
        },
        date_of_birth: {
          title: 'Geboortedatum',
          description: '',
          value: child.date_of_birth,
          type: 'date',
          error: null
        },
        lives_at_home: {
          title: 'Thuiswonend',
          description: '',
          value: child.lives_at_home ? 1 : 0,
          type: 'option',
          options: [
            { value: 1, label: 'Ja', show: true },
            { value: 0, label: 'Nee', show: true }
          ],
          error: null
        },
        studies: {
          title: 'Studerend',
          description: '',
          value: child.studies ? 1 : 0,
          type: 'option',
          options: [
            { value: 1, label: 'Ja', show: true },
            { value: 0, label: 'Nee', show: true }
          ],
          error: null
        },
        gender: {
          title: 'Geslacht',
          description: '',
          value: child.sex ? 'm' : 'f',
          type: 'option',
          options: genders,
          error: null
        }
      })
    })
    if (data.children.data.length > 0) {
      commit('submit', { name: 'customer.has_children.value', value: 1 })
    }
  }

  // TODO Pensions
  if (data.pensions && data.pensions.data.length > 0) {
    data.pensions.data.forEach(pension => {
      commit('addPension', {
        id: {
          title: 'id',
          description: '',
          value: pension.id,
          type: 'number',
          error: null
        }
      })
    })
  }

  // TODO Mortgages
  if (data.mortgages && data.mortgages.data.length > 0) {
    data.mortgages.data.forEach((mortgage, m) => {
      commit('addMortgage', {
        id: {
          title: 'id',
          description: '',
          value: mortgage.id,
          type: 'number',
          error: null
        },
        hdn_company_id: {
          title: 'Geldverstrekker',
          description: '',
          value: mortgage.hdn_company_id ? mortgage.hdn_company_id : 'none',
          type: 'option',
          options: [],
          error: null
        },
        hdn_company_label_id: {
          title: 'Product',
          description: '',
          value: mortgage.hdn_company_label_id ? mortgage.hdn_company_label_id : 'none',
          type: 'option',
          options: [],
          error: null
        },
        historische_woningwaarde: {
          title: 'Marktwaarde bekend bij Geldverstrekker',
          description: '',
          value: mortgage.original_market_value ? mortgage.original_market_value : null,
          type: 'number',
          error: null
        },
        parts: []
      })

      // Parts
      if (mortgage.parts.data) {
        mortgage.parts.data.forEach((part, p) => {
          commit('addMortgagePart', {
            m,
            mortgagePart: {
              id: {
                title: 'id',
                description: '',
                value: part.id,
                type: 'number',
                error: null
              },
              mortgage_id: {
                title: 'mortgage_id',
                description: '',
                value: mortgage.id,
                type: 'number',
                error: null
              },
              leningdeelnummer: {
                title: 'Leningdeelnummer',
                description: '',
                value: part.leningdeelnummer,
                type: 'string',
                error: null
              },
              huidige_hoogte_leningdeel: {
                title: 'Huidige hoogte leningdeel volgens laatste belastingaangifte',
                description: '',
                value: part.remaining_debt,
                type: 'amount',
                error: null,
                disabled: part.remaining_debt > 0
              },
              rentepercentage: {
                title: 'Rentepercentage',
                description: '',
                value: part.rente,
                type: 'number',
                error: null
              },
              ingangsdatum_leningdeel: {
                title: 'Ingangsdatum leningdeel',
                description: '',
                value: part.begindatum,
                type: 'date',
                error: null
              },
              einddatum_leningdeel: {
                title: 'Einddatum leningdeel',
                description: '',
                value: part.einddatum,
                type: 'date',
                error: null
              },
              einddatum_rentevastperiode: {
                title: 'Einddatum rentevastperiode',
                description: '',
                value: part.einddatumRentevast,
                type: 'date',
                error: null
              },
              aflosvorm: {
                title: 'Aflosvorm',
                description: '',
                value: part.repayment_type ? part.repayment_type : 'none',
                type: 'select',
                options: aflosvormen.filter(aflosvorm => aflosvorm.show),
                error: null
              },
              interest_type: {
                title: 'Soort rente',
                description: '',
                value: part.interest_type ? part.interest_type : 'none',
                type: 'select',
                options: rentesoorten,
                error: null
              },
              nationale_hypotheekgarantie: {
                title: 'NHG',
                description: '',
                value: part.mortgage_guarantee === 'national' ? 'nhg' : 'none',
                type: 'select',
                options: hypotheekgaranties,
                error: null
              },
              oorspronkelijke_hoogte_leningdeel: {
                title: 'Oorspronkelijke hoogte leningdeel',
                description: '',
                value: part.hoofdsom,
                type: 'amount',
                error: null
              },
              rentevast_periode_in_jaren: {
                title: 'Oorspronkelijk rentevastperiode in jaren',
                description: '',
                value: part.rentevasteperiode ? (part.rentevasteperiode / 12) : null,
                type: 'number',
                error: null
              },
              opgebouwde_spaarwaarde: {
                title: 'Opgebouwde (spaar)waarde',
                description: '',
                value: null,
                type: 'amount',
                error: null
              }
            }
          })
        })
      }
    })
  }
}

function compareStructure (current, expected) {
  // Als expected geen object is, is er niets te vergelijken
  if (typeof expected !== 'object' || expected === null) {
    return true
  }

  // Als expected een array is, controleren we of current ook een array is
  if (Array.isArray(expected)) {
    if (!Array.isArray(current)) {
      return false
    }
    // We gaan ervan uit dat als het een array is, de structuur in orde is
    return true
  }

  // Voor ieder key in expected, controleer of deze bestaat in current
  for (const key in expected) {
    if (!(key in current)) {
      return false
    }
    // Als de property een object is, voer dan een recursieve controle uit
    if (typeof expected[key] === 'object' && expected[key] !== null) {
      if (typeof current[key] !== 'object' || current[key] === null) {
        return false
      }
      if (!compareStructure(current[key], expected[key])) {
        return false
      }
    }
  }
  return true
}

const actions = {
  getStyle ({ dispatch, commit }) {
    commit('getStyle')
    tenantService.style().then(response => {
      if (response.status === 200) {
        commit('getStyleSuccess', response.data)
      } else {
        commit('getStyleFailure')
      }
    }, function (error) {
      commit('getStyleFailure')
      console.error(error)
    }).catch(error => {
      commit('getStyleFailure')
      console.error(error)
      Sentry.captureException(error)
    })
  },
  getLogo ({ dispatch, commit }) {
    commit('getLogo')
    tenantService.logo().then(response => {
      if (response.status === 200) {
        commit('getLogoSuccess', response.data)
      } else {
        commit('getLogoFailure')
      }
    }, function (error) {
      commit('getLogoFailure')
      console.error(error)
    }).catch(error => {
      commit('getLogoFailure')
      console.error(error)
      Sentry.captureException(error)
    })
  },
  getHeroImage ({ dispatch, commit }) {
    commit('getHeroImage')
    tenantService.heroImage().then(response => {
      if (response.status === 200) {
        commit('getHeroImageSuccess', response.data)
      } else {
        commit('getHeroImageFailure')
      }
    }, function (error) {
      commit('getHeroImageFailure')
      console.error(error)
    }).catch(error => {
      commit('getHeroImageFailure')
      console.error(error)
      Sentry.captureException(error)
    })
  },
  getHDNCompanies ({ dispatch, commit }) {
    commit('getHDNCompanies')
    customerService.getHDNCompanies().then(response => {
      if (response.status === 200) {
        commit('getHDNCompaniesSuccess', response.data)
      } else {
        commit('getHDNCompaniesFailure')
      }
    }, function (error) {
      commit('getHDNCompaniesFailure')
      console.error(error)
    }).catch(error => {
      commit('getHDNCompaniesFailure')
      console.error(error)
      Sentry.captureException(error)
    })
  },
  getHDNCompanyLabels ({ dispatch, commit }) {
    commit('getHDNCompanyLabels')
    customerService.getHDNCompanyLabels().then(response => {
      if (response.status === 200) {
        commit('getHDNCompanyLabelsSuccess', response.data)
      } else {
        commit('getHDNCompanyLabelsFailure')
      }
    }, function (error) {
      commit('getHDNCompanyLabelsFailure')
      console.error(error)
    }).catch(error => {
      commit('getHDNCompanyLabelsFailure')
      console.error(error)
      Sentry.captureException(error)
    })
  },
  setCustomer ({ dispatch, commit }, invitationType) {
    commit('flushState')
    commit('setCustomer')
    customerService.setCustomer(invitationType).then(response => {
      commit('getCustomerSuccess', response.data)
      commitCustomer(commit, response.data)
    }).catch((error) => {
      commit('setCustomerFailure')
      authService.logout()
      Sentry.captureException(error)
      router.push('/debug')
    })
  },
  getCustomer ({ dispatch, commit }, invitationToken) {
    commit('flushState')
    commit('getCustomer')
    customerService.getCustomer(invitationToken).then(response => {
      if (response.status === 200) {
        commit('getCustomerSuccess', response.data.data)
        commitCustomer(commit, response.data.data)
      } else {
        commit('getCustomerFailure')
        router.push('/login')
      }
    }, function (error) {
      commit('getCustomerFailure')
      console.error(error)
    }).catch((error) => {
      commit('getCustomerFailure')
      authService.logout()
      Sentry.captureException(error)
      router.push('/login')
    })
  },
  postCustomer ({ dispatch, commit }) {
    const customer = {
      id: state.customer.id.value,
      salutation: state.customer.salutation.value,
      initials: (state.customer.initials && state.customer.initials.value) ? state.customer.initials.value : null,
      first_name: state.customer.first_name.value,
      official_first_names: state.customer.official_first_names.value,
      insertion: state.customer.insertion.value,
      last_name: state.customer.last_name.value,
      date_of_birth: state.customer.date_of_birth.value,
      gender: state.customer.gender.value,
      maritial_status: state.customer.maritial_status.value,
      customer_number: state.customer.customer_number.value,
      has_partner: state.customer.has_partner.value,
      has_children: state.customer.has_children.value,
      has_mortgage_with_partner: state.customer.has_mortgage_with_partner.value,
      has_companies: state.customer.has_companies.value,
      has_employers: state.customer.has_employers.value,
      phone: state.customer.phone.value,
      email: state.customer.email.value,
      smoking: state.customer.smoking.value
    }
    customer.invitation = {
      id: state.customer.invitation.id.value
    }
    customer.address = {
      street: state.customer.address.street.value,
      number: state.customer.address.number.value,
      addition: state.customer.address.addition.value,
      postal_code: state.customer.address.postal_code.value,
      place: state.customer.address.place.value,
      woz_waarde: state.customer.address.woz_waarde.value,
      marktwaarde_woning: state.customer.address.marktwaarde_woning.value
    }

    // Partner
    if (state.customer.has_partner.value) {
      customer.partner = {
        id: state.customer.partner.id.value,
        salutation: state.customer.partner.salutation.value,
        first_name: state.customer.partner.first_name.value,
        official_first_names: state.customer.partner.official_first_names.value,
        insertion: state.customer.partner.insertion.value,
        last_name: state.customer.partner.last_name.value,
        date_of_birth: state.customer.partner.date_of_birth.value,
        gender: state.customer.partner.gender.value,
        email: state.customer.partner.email.value,
        phone: state.customer.partner.phone.value,
        different_address: state.customer.partner.different_address.value,
        salaris_bruto_per_maand: state.customer.partner.salaris_bruto_per_maand.value
      }
      // Address
      if (state.customer.partner.different_address.value) {
        customer.partner.address = {
          street: state.customer.partner.address.street.value,
          number: state.customer.partner.address.number.value,
          addition: state.customer.partner.address.addition.value,
          postal_code: state.customer.partner.address.postal_code.value,
          place: state.customer.partner.address.place.value
        }
      }
    }

    // Children
    if (state.customer.has_children) {
      const children2 = []
      state.customer.children.forEach(child => {
        children2.push({
          id: child.id.value,
          salutation: '',
          first_name: child.first_name.value,
          // official_first_names isn't set for manually added children
          official_first_names: child.official_first_names ? child.official_first_names.value : '',
          insertion: child.insertion.value,
          last_name: child.last_name.value,
          date_of_birth: child.date_of_birth.value,
          place_of_birth: child.place_of_birth.value,
          studies: child.studies.value,
          lives_at_home: child.lives_at_home.value,
          gender: child.gender.value
        })
      })
      customer.children = children2
    }

    // Mortgages
    const mortgages2 = []
    state.customer.mortgages.forEach(mortgage => {
      const parts = []
      mortgage.parts.forEach(part => {
        parts.push({
          id: part.id.value,
          mortgage_id: part.mortgage_id.value,
          leningdeelnummer: part.leningdeelnummer.value,
          huidige_hoogte_leningdeel: parseFloat(part.huidige_hoogte_leningdeel.value),
          rentepercentage: parseFloat(part.rentepercentage.value),
          ingangsdatum_leningdeel: part.ingangsdatum_leningdeel.value,
          einddatum_leningdeel: part.einddatum_leningdeel.value,
          einddatum_rentevastperiode: part.einddatum_rentevastperiode.value,
          aflosvorm: part.aflosvorm.value,
          interest_type: part.interest_type.value,
          nationale_hypotheekgarantie: part.nationale_hypotheekgarantie.value,
          oorspronkelijke_hoogte_leningdeel: part.oorspronkelijke_hoogte_leningdeel.value,
          rentevast_periode_in_jaren: part.rentevast_periode_in_jaren.value,
          opgebouwde_spaarwaarde: parseFloat(part.opgebouwde_spaarwaarde.value)
        })
      })
      mortgages2.push({
        id: mortgage.id.value,
        rsin: mortgage.rsin?.value,
        is_shared: mortgage.is_shared?.value,
        hdn_company_id: mortgage.hdn_company_id.value,
        hdn_company_label_id: mortgage.hdn_company_label_id.value,
        historische_woningwaarde: parseFloat(mortgage.historische_woningwaarde.value),
        parts
      })
    })
    customer.mortgages = mortgages2

    customer.pensions = []
    if (state.customer.pensions && state.customer.pensions.aow_uitkering && state.customer.pensions.aow_gerechtigd) {
      customer.pensions = {
        aow_uitkering: state.customer.pensions.aow_uitkering.value,
        aow_gerechtigd: state.customer.pensions.aow_gerechtigd.value,
        aow_alleenstaand: state.customer.pensions.aow_alleenstaand.value,
        aow_samenwonend: state.customer.pensions.aow_samenwonend.value,
        bruto_pensioenbedrag: state.customer.pensions.bruto_pensioenbedrag.value,
        bruto_partnerpensioenbedrag: state.customer.pensions.bruto_partnerpensioenbedrag.value,
        tot_overlijden: state.customer.pensions.tot_overlijden.value
      }
    }

    // Employers
    if (state.customer.has_employers) {
      const employers2 = []
      state.customer.employers.forEach(employer => {
        employers2.push({
          name: employer.name.value,
          tax_number: employer.tax_number.value,
          salary: employer.salary.value,
          date_of_service: employer.date_of_service.value,
          function: employer.function.value,
          payroll_tax_credit: employer.payroll_tax_credit.value,
          permanent_service: employer.permanent_service.value,
          working_hours: employer.working_hours.value,
          has_car: employer.has_car.value,
          car_addition: employer.car_addition.value,
          car_contribution: employer.car_contribution.value
        })
      })
      customer.employers = employers2
    }

    // Companies
    if (state.customer.has_companies) {
      const companies2 = []
      state.customer.companies.forEach(company => {
        companies2.push({
          name: company.name.value,
          registration_number: company.registration_number.value,
          legal_type: company.legal_type.value,
          has_personnel: company.has_personnel.value,
          apply_payroll_tax_discount: company.apply_payroll_tax_discount.value,
          risn_number: company.risn_number.value,
          sbi_code: company.sbi_code.value,
          is_major_shareholder: company.is_major_shareholder.value,
          percentage_of_shares: company.percentage_of_shares.value,
          income_percentage: company.income_percentage.value,
          average_net_profit: company.average_net_profit.value,
          start_date: company.start_date.value
        })
      })
      customer.companies = companies2
    }

    // Summary
    if (state.customer.data) {
      customer.data = state.customer.data
    } else {
      customer.data = {}
    }

    // Submit state to data wrapper
    const data = {
      style: {
        name: state.style.name,
        full_name: state.style.full_name,
        h1: state.style.h1,
        h2: state.style.h2,
        h3: state.style.h3,
        h4: state.style.h4,
        primary: state.style.primary,
        use_slogan: state.style.use_slogan,
        slogan: state.style.slogan,
        use_contact_mail: state.style.use_contact_mail,
        contact_mail: state.style.contact_mail,
        use_support_mail: state.style.use_support_mail,
        support_mail: state.style.support_mail
      },
      parseResult: state.parseResult,
      customer
    }

    const routes = getRoutes(state.customer.has_partner.value, state.customer.invitation.type.value)
    const notInState = getNotInState(state.customer.has_partner.value, state.customer.invitation.type.value)
    const housingRequirements = getHousingRequirements(state.customer.has_partner.value, state.customer.invitation.type.value)
    routes.forEach(function (route) {
      if (notInState.includes(route)) {
        // skip
      } else if (route === HOUSING_REQUIREMENTS) {
        housingRequirements.forEach(function (hrRoute) {
          data[hrRoute] = state[hrRoute]
        })
      } else {
        data[route] = state[route]
      }
    })

    // Commit
    commit('postCustomer')

    customerService.postCustomer(data, isHypotheekwacht() ? [] : state.files).then(response => {
      if (response.status === 200) {
        commit('postCustomerSuccess', response.data)
        router.push('/success')
      } else if (response.status === 422) {
        alertService.onError('Niet alle gegevens zijn correct ingevuld')
      } else {
        alertService.onError('Er is een onverwachte fout opgetreden')
        if (state.auth.redirect_fail) {
          authService.logout()
          window.location.href = state.auth.redirect_fail
        }
      }
    }).catch((error) => {
      commit('postCustomerFailure')
      alertService.onError('Gegevens kunnen niet verzonden worden')
      Sentry.captureException(error)
    })
  },
  addChild ({ commit }) {
    commit('addChild', {
      id: {
        title: 'id',
        description: '',
        value: 0,
        type: 'number',
        error: null
      },
      first_name: {
        title: 'Roepnaam',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      insertion: {
        title: 'Tussenvoegsel',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      last_name: {
        title: 'Achternaam',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      place_of_birth: {
        title: 'Geboorteplaats',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      date_of_birth: {
        title: 'Geboortedatum',
        description: '',
        value: '',
        type: 'date',
        error: null
      },
      lives_at_home: {
        title: 'Thuiswonend',
        description: '',
        value: -1,
        type: 'option',
        options: [
          { value: 1, label: 'Ja', show: true },
          { value: 0, label: 'Nee', show: true }
        ],
        error: null
      },
      studies: {
        title: 'Studerend',
        description: '',
        value: -1,
        type: 'option',
        options: [
          { value: 1, label: 'Ja', show: true },
          { value: 0, label: 'Nee', show: true }
        ],
        error: null
      },
      gender: {
        title: 'Geslacht',
        description: '',
        value: 'Maak een keuze',
        type: 'option',
        options: genders,
        error: null
      }
    })
  },
  removeChild ({ commit }, i) {
    commit('removeChild', i)
  },
  addMortgage ({ commit }) {
    commit('addMortgage', {
      id: {
        title: 'id',
        description: '',
        value: 0,
        type: 'number',
        error: null
      },
      hdn_company_id: {
        title: 'Geldverstrekker',
        description: '',
        value: 'none',
        type: 'option',
        options: [],
        error: null
      },
      hdn_company_label_id: {
        title: 'Product',
        description: '',
        value: 'none',
        type: 'option',
        options: [],
        error: null
      },
      historische_woningwaarde: {
        title: 'Marktwaarde bekend bij Geldverstrekker',
        description: '',
        value: null,
        type: 'number',
        error: null
      },
      parts: []
    })
  },
  removeMortgage ({ commit }, i) {
    commit('removeMortgage', i)
  },
  addMortgagePart ({ commit }, parameters) {
    commit('addMortgagePart', {
      m: parameters.m,
      mortgagePart: {
        id: {
          title: 'id',
          description: '',
          value: parameters.m,
          type: 'number',
          error: null
        },
        mortgage_id: {
          title: 'mortgage_id',
          description: '',
          value: parameters.mortgage.id.value,
          type: 'number',
          error: null
        },
        leningdeelnummer: {
          title: 'Leningdeelnummer',
          description: '',
          value: parameters.mortgage.parts.length + 1,
          type: 'string',
          error: null
        },
        huidige_hoogte_leningdeel: {
          title: 'Huidige hoogte leningdeel volgens laatste belastingaangifte',
          description: '',
          value: null,
          type: 'amount',
          error: null
        },
        rentepercentage: {
          title: 'Rentepercentage',
          description: '',
          value: null,
          type: 'number',
          error: null
        },
        ingangsdatum_leningdeel: {
          title: 'Ingangsdatum leningdeel',
          description: '',
          value: null,
          type: 'date',
          error: null
        },
        einddatum_leningdeel: {
          title: 'Einddatum leningdeel',
          description: '',
          value: null,
          type: 'date',
          error: null
        },
        einddatum_rentevastperiode: {
          title: 'Einddatum rentevastperiode',
          description: '',
          value: null,
          type: 'date',
          error: null
        },
        aflosvorm: {
          title: 'Aflosvorm',
          description: '',
          value: 'none',
          type: 'select',
          options: aflosvormen.filter(aflosvorm => aflosvorm.show),
          error: null
        },
        interest_type: {
          title: 'Soort rente',
          description: '',
          value: 'none',
          type: 'select',
          options: rentesoorten,
          error: null
        },
        nationale_hypotheekgarantie: {
          title: 'NHG',
          description: '',
          value: 'none',
          type: 'select',
          options: hypotheekgaranties,
          error: null
        },
        oorspronkelijke_hoogte_leningdeel: {
          title: 'Oorspronkelijke hoogte leningdeel',
          description: '',
          value: null,
          type: 'amount',
          error: null
        },
        rentevast_periode_in_jaren: {
          title: 'Oorspronkelijk rentevastperiode in jaren',
          description: '',
          value: null,
          type: 'number',
          error: null
        },
        opgebouwde_spaarwaarde: {
          title: 'Opgebouwde (spaar)waarde',
          description: '',
          value: null,
          type: 'amount',
          error: null
        }
      }
    })
  },
  removeMortgagePart ({ commit }, parameters) {
    commit('removeMortgagePart', parameters)
  },
  submitFile ({ dispatch, commit }, file) {
    const supported = ['application/pdf']
    if (supported.includes(file.type)) {
      commit('submitFile', file)
    } else {
      alertService.onError('File not supported')
    }
  },
  deleteFile ({ dispatch, commit }, filename) {
    const files = []
    state.files.forEach((file) => {
      if (file.filename !== filename) {
        files.push(file)
      }
    })
    commit('deleteFile', files)
  },
  addEmployer ({ commit }) {
    commit('addEmployer', {
      name: {
        title: 'Naam',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      tax_number: {
        title: 'Loonheffingennummer',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      salary: {
        title: 'Salaris (bruto per jaar)',
        description: '',
        value: 0,
        type: 'amount',
        error: null
      },
      date_of_service: {
        title: 'Datum in dienst',
        description: '',
        value: null,
        type: 'date',
        error: null
      },
      function: {
        title: 'Functie',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      permanent_service: {
        title: 'Bent u in vaste dienst?',
        description: '',
        value: 1,
        type: 'option',
        options: [
          { value: 1, label: 'Ja', show: true },
          { value: 0, label: 'Nee', show: true }
        ],
        error: null
      },
      payroll_tax_credit: {
        title: 'Hebt u loonheffingskorting?',
        description: '',
        value: 1,
        type: 'option',
        options: [
          { value: 1, label: 'Ja', show: true },
          { value: 0, label: 'Nee', show: true }
        ],
        error: null
      },
      working_hours: {
        title: 'Uren (per maand)',
        description: '',
        value: 0,
        type: 'number',
        error: null
      },
      has_car: {
        title: 'Hebt u een auto van de zaak?',
        description: '',
        value: 0,
        type: 'option',
        options: [
          { value: 1, label: 'Ja', show: true },
          { value: 0, label: 'Nee', show: true }
        ],
        error: null
      },
      car_addition: {
        title: 'Bijtelling (per maand)',
        description: '',
        value: 0,
        type: 'amount',
        error: null
      },
      car_contribution: {
        title: 'Eigen bijdrage (per maand)',
        description: '',
        value: 0,
        type: 'amount',
        error: null
      }
    })
  },
  removeEmployer ({ commit }, i) {
    commit('removeEmployer', i)
  },
  addCompany ({ commit }) {
    commit('addCompany', {
      name: {
        title: 'Naam',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      registration_number: {
        title: 'KvK-nummer',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      risn_number: {
        title: 'RSIN',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      sbi_code: {
        title: 'SBI-code',
        description: '',
        value: '',
        type: 'text',
        error: null
      },
      legal_type: {
        title: 'Rechtsvorm',
        description: '',
        value: 'none',
        type: 'option',
        options: companyLegalTypeOptions,
        error: null
      },
      apply_payroll_tax_discount: {
        title: 'Loonheffingskorting',
        description: '',
        value: 0,
        type: 'option',
        options: [
          { value: 1, label: 'Ja', show: true },
          { value: 0, label: 'Nee', show: true }
        ],
        error: null
      },
      has_personnel: {
        title: 'Heeft personeel',
        description: '',
        value: 0,
        type: 'option',
        options: [
          { value: 1, label: 'Ja', show: true },
          { value: 0, label: 'Nee', show: true }
        ],
        error: null
      },
      is_major_shareholder: {
        title: 'DGA',
        description: '',
        value: 0,
        type: 'option',
        options: [
          { value: 1, label: 'Ja', show: true },
          { value: 0, label: 'Nee', show: true }
        ],
        error: null
      },
      percentage_of_shares: {
        title: 'Percentage aandelen',
        description: '',
        value: 0,
        type: 'number',
        error: null
      },
      income_percentage: {
        title: 'Percentage meetellen',
        description: '',
        value: 0,
        type: 'number',
        error: null
      },
      average_net_profit: {
        title: 'Gemiddeld netto jaarwinst',
        description: '',
        value: 0,
        type: 'amount',
        error: null
      },
      start_date: {
        title: 'Startdatum onderneming',
        description: '',
        value: null,
        type: 'date',
        error: null
      }
    })
  },
  removeCompany ({ commit }, i) {
    commit('removeCompany', i)
  },
  addPension ({ commit }) {
    commit('addPension', {})
  },
  removePension ({ commit }, i) {
    commit('removePension', i)
  },
  submit ({ commit }, { name, value }) {
    commit('submit', { name, value })
  },
  submitAqopi ({ commit }, data) {
    commit('submitAqopi', data)
  },
  validate ({ commit }, { key, data }) {
    if (key === PERSONAL_DETAILS) {
      commit('validatePersonalDetails', data)
    } else if (key === PERSONAL_CHARACTERISTICS) {
      commit('validatePersonalCharacteristics', data)
    } else if (key === CURRENT_LIVING_CONDITION) {
      commit('validateCurrentLivingCondition', data)
    } else if (key === MOVING) {
      commit('validateMoving', data)
    } else if (key === FINANCING) {
      commit('validateFinancing', data)
    } else if (key === CHILDREN) {
      commit('validateChildren', data)
    } else if (key === UNEMPLOYMENT) {
      commit('validateUnemployment', data)
    } else if (key === HOUSING_REQUIREMENTS) {
      commit('validateHousingRequirements', data)
    } else if (key === SUSTAINABILITY) {
      commit('validateSustainability', data)
    } else if (key === INCAPACITY_WORK) {
      commit('validateIncapacityWork', data)
    } else if (key === DEATH_OF_PARTNER) {
      commit('validateDeathOfPartner', data)
    } else if (key === DIVORCE) {
      commit('validateDivorce', data)
    } else if (key === PENSION) {
      commit('validatePension', data)
    } else if (key === MORTGAGE) {
      commit('validateMortgage', data)
    } else if (key === MORTGAGE_AND_PENSION) {
      commit('validateMortgageAndPension', data)
    } else if (key === QUESTIONS_FOR_THE_ADVISOR) {
      commit('validateQuestions', data)
    }
  }
}

const mutations = {
  flushState (state) {
    state = initialState
    localStorage.setItem('survey', JSON.stringify(initialState))
  },
  getStyle (state) {
    state.style.loading = true
  },
  getStyleFailure (state) {
    state.style.loading = false
  },
  getStyleSuccess (state, data) {
    state.style.loading = false
    state.style.name = data.name
    state.style.email = data.contact_email
    state.style.primary.backgroundColor = data.primary_color
    state.style.primary.borderColor = data.primary_color
    state.style.primary.color = '#ffffff'
    state.style.h1.color = data.secondary_color
    state.style.h2.color = data.tertiary_color
    state.style.h3.color = data.tertiary_color
    state.style.h4.color = data.tertiary_color
    state.style.use_slogan = data.use_slogan
    state.style.slogan = data.slogan
    state.style.use_contact_email = data.use_contact_email
    state.style.contact_email = data.contact_email
    state.style.use_support_email = data.use_support_email
    state.style.support_email = data.support_email
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  getLogo (state) {
    state.style.loading = true
  },
  getLogoFailure (state) {
    state.style.loading = false
  },
  getLogoSuccess (state, data) {
    state.style.loading = false
    state.style.logo = data
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  getHeroImage (state) {
    state.style.loading = true
  },
  getHeroImageFailure (state) {
    state.style.loading = false
  },
  getHeroImageSuccess (state, data) {
    state.style.loading = false
    state.style.hero_image = './assets/hero-image.jpg' // data
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  getHDNCompanies (state) {
    state.hdn_companies.loading = true
    state.hdn_companies.companies = []
  },
  getHDNCompaniesFailure (state) {
    state.hdn_companies.loading = false
  },
  getHDNCompaniesSuccess (state, data) {
    state.hdn_companies.loading = false
    state.hdn_companies.companies = []
    data.forEach((company) => {
      state.hdn_companies.companies.push({
        id: company.id,
        code: company.hdn_code,
        name: company.sender_name
      })
    })
  },
  getHDNCompanyLabels (state) {
    state.hdn_company_labels.loading = true
    state.hdn_company_labels.labels = []
  },
  getHDNCompanyLabelsFailure (state) {
    state.hdn_company_labels.loading = false
  },
  getHDNCompanyLabelsSuccess (state, data) {
    state.hdn_company_labels.loading = false
    state.hdn_company_labels.labels = []
    data.forEach((label) => {
      state.hdn_company_labels.labels.push({
        id: label.id,
        company_id: label.company_id,
        hdn_company_label_id: label.hdn_company_label_id,
        code: label.hdn_code,
        name: label.name
      })
    })
  },
  deleteFile (state, files) {
    state.files = files
  },
  submitFile (state, file) {
    const reader = new FileReader()
    reader.onload = () => {
      state.files.push({ filename: file.name, type: file.type, payload: reader.result })
    }
    reader.readAsDataURL(file)
  },
  setCustomer (state) {
    state.status.loading = true
  },
  setCustomerFailure (state) {
    state.status.loading = false
  },
  setCustomerSuccess (state) {
    state.status.loading = false
  },
  getCustomer (state) {
    state.status.loading = true
  },
  getCustomerFailure (state) {
    state.status.loading = false
  },
  getCustomerSuccess (state, data) {
    const initialCustomerState = initialState.customer

    // Controleer voor ieder attribuut in state.customer
    if (!state.customer || !compareStructure(state.customer, initialCustomerState)) {
      Sentry.addBreadcrumb({
        type: 'error',
        level: 'error',
        message: 'The structure of the customer\'s state is invalid!',
        data: {
          initialState: initialCustomerState,
          currentState: state.customer
        }
      })

      Sentry.captureException(new Error('The customer state is invalid! Resetting the state.'))

      // Als de structuur niet klopt, reset dan de gehele state
      Object.assign(state, initialCustomerState)
    }

    state.status.loading = false
    state.status.version = initialState.status.version
    state.customer.id.value = data.id ? data.id : null
    state.customer.is_main_customer.value = data.is_main_customer ? data.is_main_customer : true
    state.customer.is_partner.value = data.is_partner ? data.is_partner : false
    state.customer.salutation.value = (data.salutation ? data.salutation : (data.sex ? 'De heer' : 'Mevrouw'))
    state.customer.initials.value = data.initials ? data.initials : ''
    state.customer.first_name.value = data.first_name ? data.first_name : ''
    state.customer.official_first_names.value = data.official_first_names ? data.official_first_names : ''
    state.customer.insertion.value = data.suffix ? data.suffix : ''
    state.customer.last_name.value = data.last_name ? data.last_name : ''
    state.customer.date_of_birth.value = data.date_of_birth
    state.customer.maritial_status.value = data.maritial_status
    state.customer.smoking.value = data.smoking ? data.smoking : 0
    state.customer.customer_number.value = ''
    state.customer.gender.value = data.sex ? 'm' : 'f'
    state.customer.email.value = data.email
    state.customer.phone.value = data.phone
    state.customer.has_partner.value = 0
    state.customer.has_children.value = 0
    state.customer.has_mortgage_with_partner.value = 1
    state.customer.children = []
    state.customer.employers = []
    state.customer.companies = []
    state.customer.pensions = []
    state.customer.mortgages = []
    state.customer.is_pensioned.value = getAge(data.date_of_birth) > 67 ? 1 : 0
    state.customer.has_employers.value = 0
    state.customer.has_companies.value = 0
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
    Sentry.setTag('invitation_type', state.customer.invitation.type.value)
    Sentry.setTag('customer_id', state.customer.id.value)
    Sentry.setUser({
      id: data.id,
      username: data.username,
      email: data.email
    })
  },
  postCustomer (state) {
    state.status.loading = true
  },
  postCustomerSuccess (state, data) {
    state = initialState
    state.customer.invitation.id.value = data.id
    state.customer.invitation.type.value = data.type
    state.customer.invitation.valid_until.value = data.valid_until
    state.customer.invitation.submitted_at.value = data.submitted_at
    state.customer.invitation.partner_invited.value = data.partner_invited
    localStorage.setItem('survey', JSON.stringify(state))
  },
  postCustomerFailure (state) {
    state.status.loading = false
  },
  addInvitation (state, invitation) {
    state.customer.has_valid_invitation = true
    state.customer.invitation.id.value = invitation.id
    state.customer.invitation.type.value = invitation.type ? invitation.type : 'full'
    state.customer.invitation.valid_until.value = invitation.valid_until ? invitation.valid_until : null
    state.customer.invitation.submitted_at.value = invitation.submitted_at ? invitation.submitted_at : null
    state.customer.invitation.partner_invited.value = invitation.partner_invited ? invitation.partner_invited : false
    state.customer.invitation.aqopi_info = invitation.aqopi_info.data ? invitation.aqopi_info.data : null
    state.isHypotheekwacht = isHypotheekwacht(invitation.type)
    localStorage.setItem('survey', JSON.stringify(state))
  },
  setHasValidInvitation (state, boolValue) {
    state.customer.has_valid_invitation = boolValue
  },
  addAddress (state, address) {
    state.customer.address.street.value = address.street ? address.street : ''
    state.customer.address.number.value = address.number ? address.number : ''
    state.customer.address.addition.value = address.addition ? address.addition : ''
    state.customer.address.postal_code.value = address.postcode ? address.postcode : ''
    state.customer.address.place.value = address.place ? address.place : ''
  },
  addPartner (state, partner) {
    state.customer.partner.id.value = partner.id ? partner.id : null
    state.customer.partner.first_name.value = partner.first_name ? partner.first_name : ''
    state.customer.partner.official_first_names.value = partner.official_first_names ? partner.official_first_names : ''
    state.customer.partner.insertion.value = partner.insertion ? partner.insertion : ''
    state.customer.partner.last_name.value = partner.last_name ? partner.last_name : ''
    state.customer.partner.date_of_birth.value = partner.date_of_birth ? partner.date_of_birth : null
    state.customer.partner.gender.value = partner.sex ? 'm' : 'f'
    state.customer.partner.phone.value = ''
    state.customer.partner.email.value = partner.email ? partner.email : ''
    state.customer.partner.different_address.value = partner.non_resident === 1 ? 0 : 1
  },
  addPartnerAddress (state, address) {
    state.customer.partner.address.street.value = address.street ? address.street : ''
    state.customer.partner.address.number.value = address.number ? address.number : ''
    state.customer.partner.address.addition.value = address.addition ? address.addition : ''
    state.customer.partner.address.postal_code.value = address.postcode ? address.postcode : ''
    state.customer.partner.address.place.value = address.place ? address.place : ''
  },
  addOffice (state, office) {
    state.customer.office.city.value = office.city ? office.city : ''
    state.customer.office.code.value = office.code ? office.code : ''
    state.customer.office.email.value = office.email ? office.email : ''
    state.customer.office.house_number.value = office.house_number ? office.house_number : ''
    state.customer.office.house_number_addition.value = office.house_number_addition ? office.house_number_addition : ''
    state.customer.office.name.value = office.name ? office.name : ''
    state.customer.office.phone.value = office.phone ? office.phone : ''
    state.customer.office.postcode.value = office.postcode ? office.postcode : ''
    state.customer.office.street_name.value = office.street_name ? office.street_name : ''
    state.customer.office.website.value = office.website ? office.website : ''
  },
  addOfficeStyle (state, style) {
    state.customer.office.style.logo.value = style.logo && style.logo.data ? style.logo.data : null
    state.customer.office.style.content_type.value = style.logo && style.logo.content_type ? style.logo.content_type : null
  },
  removePartner (state) {
    state.customer.partner = null
  },
  addChild (state, child) {
    const children = state.customer.children
    children.push(child)
    state = { ...state.customer.children = children }
  },
  removeChild (state, c) {
    const children = state.customer.children
    children.splice(c, 1)
    state = { ...state.customer.children = children }
  },
  addMortgage (state, mortgage) {
    const mortgages = state.customer.mortgages
    mortgages.push(mortgage)
    state = { ...state.customer.mortgages = mortgages }
  },
  removeMortgage (state, m) {
    const mortgages = state.customer.mortgages
    mortgages.splice(m, 1)
    state = { ...state.customer.mortgages = mortgages }
  },
  addMortgagePart (state, { m, mortgagePart }) {
    const mortgageParts = state.customer.mortgages[m].parts
    mortgageParts.push(mortgagePart)
    state = { ...state.customer.mortgages[m].parts = mortgageParts }
  },
  removeMortgagePart (state, { m, p }) {
    const mortgageParts = state.customer.mortgages[m].parts
    mortgageParts.splice(p, 1)
    state = { ...state.customer.mortgages[m].parts = mortgageParts }
  },
  addEmployer (state, employer) {
    const employers = state.customer.employers
    employers.push(employer)
    state = { ...state.customer.employers = employers }
  },
  removeEmployer (state, e) {
    const employers = state.customer.employers
    employers.splice(e, 1)
    state = { ...state.customer.employers = employers }
  },
  addCompany (state, company) {
    const companies = state.customer.companies
    companies.push(company)
    state = { ...state.customer.companies = companies }
  },
  removeCompany (state, i) {
    const companies = state.customer.companies
    companies.splice(i, 1)
    state = { ...state.customer.companies = companies }
  },
  addPension (state, pension) {
    const pensions = state.customer.pensions
    pensions.push(pension)
    state = { ...state.customer.pensions = pensions }
  },
  removePension (state, p) {
    const pensions = state.customer.pensions
    pensions.splice(p, 1)
    state = { ...state.customer.pensions = pensions }
  },
  submitAqopi (state, data) {
    state.parseResult = data.parseResult

    const customer = state.customer
    const personalCharacteristics = state.personal_characteristics
    const currentLivingCondition = state.current_living_condition
    const moving = state.moving
    const children = state.children
    const unemployment = state.unemployment
    const incapacityWork = state.incapacity_work
    const deathOfPartner = state.death_of_partner
    const divorce = state.divorce
    const pension = state.pension
    const mortgage = state.mortgage
    const questions = state.questions

    // Persoonsgegevens
    if (data.summary.data.persoonsgegevens) {
      if (data.summary.data.persoonsgegevens.aanvrager) {
        if (data.summary.data.persoonsgegevens.aanvrager.voornamen) {
          customer.first_name.value = data.summary.data.persoonsgegevens.aanvrager.voornamen.val
            ? data.summary.data.persoonsgegevens.aanvrager.voornamen.val
            : ''
          customer.official_first_names.value = data.summary.data.persoonsgegevens.aanvrager.voornamen.val
            ? data.summary.data.persoonsgegevens.aanvrager.voornamen.val
            : ''
        }
        if (data.summary.data.persoonsgegevens.aanvrager.initialen) {
          customer.initials.value = data.summary.data.persoonsgegevens.aanvrager.initialen.val
            ? data.summary.data.persoonsgegevens.aanvrager.initialen.val
            : ''
        }
        if (data.summary.data.persoonsgegevens.aanvrager.voorvoegselsGeslachtsnaam) {
          customer.insertion.value = data.summary.data.persoonsgegevens.aanvrager.voorvoegselsGeslachtsnaam.val
            ? data.summary.data.persoonsgegevens.aanvrager.voorvoegselsGeslachtsnaam.val
            : ''
        }
        if (data.summary.data.persoonsgegevens.aanvrager.achternaam || data.summary.data.persoonsgegevens.aanvrager.geslachtsnaam) {
          customer.last_name.value = data.summary.data.persoonsgegevens.aanvrager.geslachtsnaam.val
            ? data.summary.data.persoonsgegevens.aanvrager.geslachtsnaam.val
            : data.summary.data.persoonsgegevens.aanvrager.achternaam.val
        }
        if (data.summary.data.persoonsgegevens.aanvrager.geboortedatum) {
          customer.date_of_birth.value = data.summary.data.persoonsgegevens.aanvrager.geboortedatum.val
            ? data.summary.data.persoonsgegevens.aanvrager.geboortedatum.val
            : ''
        }
        if (data.summary.data.persoonsgegevens.aanvrager.geslacht) {
          customer.gender.value = data.summary.data.persoonsgegevens.aanvrager.geslacht.val === 'Man' ? 'm' : 'f'
        }
        if (data.summary.data.persoonsgegevens.aanvrager.geboortedatum) {
          customer.is_pensioned.value = getAge(data.summary.data.persoonsgegevens.aanvrager.geboortedatum.val) > 67 ? 1 : 0
        }
        if (data.summary.data.persoonsgegevens.aanvrager.burgerlijkeStaat && data.summary.data.persoonsgegevens.aanvrager.burgerlijkeStaat.gehuwd) {
          const burgerlijkeStaat = data.summary?.data?.persoonsgegevens?.aanvrager?.burgerlijkeStaat || {}

          const gehuwd = burgerlijkeStaat.gehuwd?.val
          const geregistreerdPartnerschap = burgerlijkeStaat.geregistreerdPartnerschap?.val
          const samenwonend = burgerlijkeStaat.indSamenwonendEnFiscaalPartnerschap?.val

          if (gehuwd) {
            customer.maritial_status.value = 1
          } else if (geregistreerdPartnerschap) {
            customer.maritial_status.value = 3
          } else if (samenwonend) {
            customer.maritial_status.value = 6
          } else {
            customer.maritial_status.value = -1
          }
        }

        if (customer.gender.value === 'm' || customer.gender.value === 'f') {
          customer.salutation.value = (customer.gender.value === 'm' ? 'De heer' : 'Mevrouw')
        } else {
          customer.salutation.value = ''
        }

        customer.has_partner.value = 0
        customer.has_children.value = 0
        customer.has_employers.value = 0
        customer.has_companies.value = 0
        customer.smoking.value = 0
      }

      // Partner
      if (data.summary.data.persoonsgegevens.partner && data.summary.data.persoonsgegevens.partner.val) {
        customer.partner.date_of_birth.value = data.summary.data.persoonsgegevens.partner.val.geboortedatum
          ? data.summary.data.persoonsgegevens.partner.val.geboortedatum
          : ''
        customer.partner.first_name.value = data.summary.data.persoonsgegevens.partner.val.initialen
          ? data.summary.data.persoonsgegevens.partner.val.initialen
          : ''
        customer.partner.official_first_names.value = data.summary.data.persoonsgegevens.partner.val.voornamen
          ? data.summary.data.persoonsgegevens.partner.val.voornamen
          : ''
        customer.partner.insertion.value = data.summary.data.persoonsgegevens.partner.val.voorvoegselsGeslachtsnaam
          ? data.summary.data.persoonsgegevens.partner.val.voorvoegselsGeslachtsnaam
          : ''
        customer.partner.last_name.value = data.summary.data.persoonsgegevens.partner.val.geslachtsnaam
          ? data.summary.data.persoonsgegevens.partner.val.geslachtsnaam
          : data.summary.data.persoonsgegevens.partner.val.achternaam
        customer.partner.salutation.value = ''
        customer.partner.email.value = ''
        customer.partner.gender.value = ''
        customer.has_partner.value = 1
      }

      // Address
      if (data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd && data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd.val && data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd.val.length > 0) {
        customer.address.street.value = data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd.val[0].straat
        customer.address.number.value = data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd.val[0].huisnummer
        customer.address.addition.value = data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd.val[0].huisnummerToevoeging
        customer.address.postal_code.value = data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd.val[0].postcode
        customer.address.place.value = data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd.val[0].woonplaatsnaam
        customer.address.woz_waarde.value = data.summary.data.persoonsgegevens.adressenOnroerendGoedGecombineerd.val[0].wozWaarde
      } else if (data.summary.data.bezittingen && data.summary.data.bezittingen.onroerendGoed && data.summary.data.bezittingen.onroerendGoed.val && data.summary.data.bezittingen.onroerendGoed.val.length > 0) {
        // '01' = Bewoond door eigenaar
        const filteredCollateral = data.summary.data.bezittingen.onroerendGoed.val.filter(onroerendGoed => onroerendGoed.situatieBewoning === '01')

        if (filteredCollateral.length > 0) {
          const collateral = filteredCollateral[0]

          customer.address.street.value = collateral.straat
          customer.address.number.value = collateral.huisnummer
          customer.address.addition.value = collateral.huisnummerToevoeging
          customer.address.postal_code.value = collateral.postcode
          customer.address.place.value = collateral.woonplaatsnaam
          customer.address.woz_waarde.value = collateral.wozWaarde
        }
      }

      // Children
      const children2 = []
      if (data.summary.data.persoonsgegevens.kinderen) {
        data.summary.data.persoonsgegevens.kinderen.val.forEach(child => {
          if (getAge(child.geboortedatum) < 30) {
            // Child
            children2.push({
              id: {
                title: 'id',
                description: '',
                value: child.id,
                type: 'number',
                error: null
              },
              salutation: {
                title: 'Aanhef',
                description: '',
                value: '',
                type: 'text',
                error: null
              },
              initials: {
                title: 'Initialen',
                description: '',
                value: child.initialen ? child.initialen : '',
                type: 'text',
                error: null
              },
              first_name: {
                title: 'Roepnaam',
                description: '',
                value: child.voornamen,
                type: 'text',
                error: null
              },
              official_first_names: {
                title: 'Officiële voornamen',
                description: '',
                value: child.voornamen,
                type: 'text',
                error: null
              },
              insertion: {
                title: 'Tussenvoegsel',
                description: '',
                value: '',
                type: 'text',
                error: null
              },
              last_name: {
                title: 'Achternaam',
                description: '',
                value: child.geslachtsnaam,
                type: 'text',
                error: null
              },
              place_of_birth: {
                title: 'Geboorteplaats',
                description: '',
                value: child.geboorteplaats,
                type: 'text',
                error: null
              },
              date_of_birth: {
                title: 'Geboortedatum',
                description: '',
                value: child.geboortedatum,
                type: 'date',
                error: null
              },
              lives_at_home: {
                title: 'Thuiswonend',
                description: '',
                value: getAge(child.geboortedatum) < 25 ? 1 : 0,
                options: [
                  { value: 1, label: 'Ja', show: true },
                  { value: 0, label: 'Nee', show: true }
                ],
                type: 'option',
                error: null
              },
              studies: {
                title: 'Studerend',
                description: '',
                value: false,
                options: [
                  { value: 1, label: 'Ja', show: true },
                  { value: 0, label: 'Nee', show: true }
                ],
                type: 'option',
                error: null
              },
              gender: {
                title: 'Geslacht',
                description: '',
                value: 'Maak een keuze',
                type: 'option',
                options: genders,
                error: null
              }
            })
          }
        })
      }

      customer.has_children.value = children2.length > 0 ? 1 : 0
      customer.children = children2
      customer.data = {}
      customer.data = data.summary.data
      state.customer = customer
    }

    // Bezittingen
    if (data.summary.data.bezittingen && data.summary.data.bezittingen.onroerendGoed) {
      if (data.summary.data.bezittingen.onroerendGoed.val && data.summary.data.bezittingen.onroerendGoed.val[0]) {
        if (customer.address.woz_waarde.value === null || customer.address.woz_waarde.value === undefined) {
          customer.address.woz_waarde.value = data.summary.data.bezittingen.onroerendGoed.val[0].wozWaarde
        }
      }
    }

    // Werkgevers
    if (data.summary.data.inkomens) {
      const employers2 = []
      const inkomens = data.summary.data.inkomens
      if (inkomens.werkgevers && inkomens.werkgevers.val.length > 0) {
        inkomens.werkgevers.val.forEach(werkgever => {
          if (werkgever.indIsHuidigeWerkgever) {
            const slip = werkgever.slips.length > 0 ? werkgever.slips[0] : null
            const hasCar = slip !== null && (slip.eigenBijdrageAuto > 0 || slip.fiscaleBijtellingAuto > 0)
            const carAddition = slip !== null ? parseInt(slip.fiscaleBijtellingAuto) : 0
            const carContribution = slip !== null ? parseInt(slip.eigenBijdrageAuto) : 0
            employers2.push({
              name: {
                title: 'Werkgever',
                description: '',
                value: werkgever.werkgever,
                type: 'text',
                error: null
              },
              tax_number: {
                title: 'Loonheffingennummer',
                description: '',
                value: werkgever.loonheffingenNr,
                type: 'text',
                error: null
              },
              salary: {
                title: 'Salaris (bruto per jaar)',
                description: '',
                value: ((werkgever.salaris + carContribution - carAddition) * 12),
                type: 'amount',
                error: null
              },
              date_of_service: {
                title: 'Datum in dienst',
                description: '',
                value: null,
                type: 'date',
                error: null
              },
              function: {
                title: 'Functie',
                description: '',
                value: '',
                type: 'text',
                error: null
              },
              permanent_service: {
                title: 'Vaste dienst',
                description: '',
                value: werkgever.indInVasteDienst,
                type: 'option',
                options: [
                  { value: 1, label: 'Ja', show: true },
                  { value: 0, label: 'Nee', show: true }
                ],
                error: null
              },
              payroll_tax_credit: {
                title: 'Hebt u loonheffingskorting?',
                description: '',
                value: 0,
                type: 'option',
                options: [
                  { value: 1, label: 'Ja', show: true },
                  { value: 0, label: 'Nee', show: true }
                ],
                error: null
              },
              working_hours: {
                title: 'Uren (per maand)',
                description: '',
                value: slip !== null ? slip.aantalUren : 0,
                type: 'number',
                error: null
              },
              has_car: {
                title: 'Hebt u een auto van de zaak?',
                description: '',
                value: hasCar,
                type: 'option',
                options: [
                  { value: 1, label: 'Ja', show: true },
                  { value: 0, label: 'Nee', show: true }
                ],
                error: null
              },
              car_addition: {
                title: 'Bijtelling',
                description: '',
                value: carAddition,
                type: 'amount',
                error: null
              },
              car_contribution: {
                title: 'Eigen bijdrage',
                description: '',
                value: carContribution,
                type: 'amount',
                error: null
              }
            })
            customer.has_employers.value = 1
          }
        })
        customer.employers = employers2
      } else if (inkomens.belastingaangifte && inkomens.belastingaangifte.inkomens && inkomens.belastingaangifte.inkomens.val.length > 0) {
        /** salary -> value: verwijder vakantiegeld indien hypotheekwacht **/
        inkomens.belastingaangifte.inkomens.val.forEach(werkgever => {
          employers2.push({
            name: {
              title: 'Werkgever',
              description: '',
              value: werkgever.instantieWerkgever,
              type: 'text',
              error: null
            },
            salary: {
              title: 'Salaris (bruto per jaar)',
              description: '',
              value: isHypotheekwacht() && werkgever.brutoloon > 0 ? (werkgever.brutoloon / 108 * 100).toFixed(2) : werkgever.brutoloon,
              type: 'amount',
              error: null
            },
            tax_number: {
              title: 'Loonheffingennummer',
              description: '',
              value: null,
              type: 'text',
              error: null
            },
            date_of_service: {
              title: 'Datum in dienst',
              description: '',
              value: null,
              type: 'date',
              error: null
            },
            function: {
              title: 'Functie',
              description: '',
              value: null,
              type: 'text',
              error: null
            },
            permanent_service: {
              title: 'Vaste dienst',
              description: '',
              value: 0,
              type: 'option',
              options: [
                { value: 1, label: 'Ja', show: true },
                { value: 0, label: 'Nee', show: true }
              ],
              error: null
            },
            payroll_tax_credit: {
              title: 'Hebt u loonheffingskorting?',
              description: '',
              value: 0,
              type: 'option',
              options: [
                { value: 1, label: 'Ja', show: true },
                { value: 0, label: 'Nee', show: true }
              ],
              error: null
            },
            working_hours: {
              title: 'Uren (per maand)',
              description: '',
              value: 0,
              type: 'number',
              error: null
            },
            has_car: {
              title: 'Hebt u een auto van de zaak?',
              description: '',
              value: 0,
              type: 'option',
              options: [
                { value: 1, label: 'Ja', show: true },
                { value: 0, label: 'Nee', show: true }
              ],
              error: null
            },
            car_addition: {
              title: 'Bijtelling',
              description: '',
              value: null,
              type: 'amount',
              error: null
            },
            car_contribution: {
              title: 'Eigen bijdrage',
              description: '',
              value: null,
              type: 'amount',
              error: null
            }
          })
          customer.has_employers.value = 1
        })
        customer.employers = employers2
      }
    }

    // Schulden
    if (data.summary.data.schulden) {
      const mortgages2 = []
      const schulden = data.summary.data.schulden
      if (schulden.hypotheekdelen && schulden.hypotheekdelen.val.length > 0) {
        let mort = null
        schulden.hypotheekdelen.val.forEach((hypotheekdeel, m) => {
          let mappedAflosVorm = 'none'
          let hdnCompany

          let labelObject
          // Eerst zoeken we in de company labels (ervan uitgaande dat dit een array is)
          if (state.hdn_company_labels && state.hdn_company_labels.length > 0) {
            labelObject = state.hdn_company_labels.find(label => label.name === hypotheekdeel.label)
            if (labelObject) {
              // We nemen aan dat het label-object een property 'company_id' heeft waarmee we de company vinden.
              hdnCompany = state.hdn_companies.companies.find(company => company.id === labelObject.company_id)
            }
          }

          // Als we via de labels nog geen company hebben gevonden, zoek obv hdnMijCode
          if (!hdnCompany && state.hdn_companies.companies.length > 0) {
            hdnCompany = state.hdn_companies.companies.find(company => company.code === hypotheekdeel.hdnMijCode)
          }

          if (hypotheekdeel.soortHypotheekAfgeleid && hypotheekdeel.soortHypotheekAfgeleid.length > 0) {
            let filteredAflosvormen = aflosvormen.filter(aflosvorm => aflosvorm.label === hypotheekdeel.soortHypotheekAfgeleid)

            if (filteredAflosvormen.length === 1) {
              mappedAflosVorm = filteredAflosvormen[0].value
            } else {
              filteredAflosvormen = aflosvormen.filter(aflosvorm => aflosvorm.variants.includes(hypotheekdeel.soortHypotheekAfgeleid))

              if (filteredAflosvormen.length === 1) {
                mappedAflosVorm = filteredAflosvormen[0].value
              }
            }
          }

          if (mort === null) {
            mort = {
              id: {
                title: 'id',
                description: '',
                value: 0,
                type: 'number',
                error: null
              },
              rsin: {
                title: 'rsin',
                description: '',
                value: hypotheekdeel.rsin,
                type: 'string',
                error: null
              },
              is_shared: {
                title: 'Gedeelde hypotheek',
                description: '',
                value: hypotheekdeel.gezamelijkeSchuld,
                type: 'string',
                error: null
              },
              hdn_company_id: {
                title: 'Geldverstrekker',
                description: '',
                value: hdnCompany ? hdnCompany.id : 'none',
                type: 'number',
                options: [],
                error: null
              },
              hdn_company_label_id: {
                title: 'Product',
                description: '',
                value: typeof labelObject !== 'undefined' && labelObject !== null ? labelObject.id : 'none',
                type: 'option',
                options: [],
                error: null
              },
              historische_woningwaarde: {
                title: 'Marktwaarde bekend bij Geldverstrekker',
                description: '',
                value: null,
                type: 'number',
                error: null
              },
              parts: []
            }
            mortgages2.push(mort)
          }
          mort.parts.push({
            id: {
              title: 'id',
              description: '',
              value: null,
              type: 'number',
              error: null
            },
            mortgage_id: {
              title: 'mortgage_id',
              description: '',
              value: mort.id.value,
              type: 'number',
              error: null
            },
            leningdeelnummer: {
              title: 'Leningdeelnummer',
              description: '',
              value: hypotheekdeel.rekeningnummer,
              type: 'string',
              error: null
            },
            huidige_hoogte_leningdeel: {
              title: 'Huidige hoogte leningdeel volgens laatste belastingaangifte',
              description: '',
              value: hypotheekdeel.eindstand,
              type: 'amount',
              error: null,
              disabled: hypotheekdeel.eindstand > 0
            },
            rentepercentage: {
              title: 'Rentepercentage',
              description: '',
              value: hypotheekdeel.rentePercentageBerekend,
              type: 'number',
              error: null
            },
            ingangsdatum_leningdeel: {
              title: 'Ingangsdatum leningdeel',
              description: '',
              value: hypotheekdeel.begindatumBerekend,
              type: 'date',
              error: null
            },
            einddatum_leningdeel: {
              title: 'Einddatum leningdeel',
              description: '',
              value: hypotheekdeel.einddatumBerekend,
              type: 'date',
              error: null
            },
            einddatum_rentevastperiode: {
              title: 'Einddatum rentevastperiode',
              description: '',
              value: null,
              type: 'date',
              error: null
            },
            aflosvorm: {
              title: 'Aflosvorm',
              description: '',
              value: mappedAflosVorm,
              type: 'select',
              options: aflosvormen.filter(aflosvorm => aflosvorm.show),
              error: null
            },
            interest_type: {
              title: 'Soort rente',
              description: '',
              value: 'none',
              type: 'select',
              options: rentesoorten,
              error: null
            },
            nationale_hypotheekgarantie: {
              title: 'NHG',
              description: '',
              value: 'none',
              type: 'select',
              options: hypotheekgaranties,
              error: null
            },
            oorspronkelijke_hoogte_leningdeel: {
              title: 'Oorspronkelijke hoogte leningdeel',
              description: '',
              value: hypotheekdeel.startBedrag || hypotheekdeel.beginstand,
              type: 'amount',
              error: null
            },
            rentevast_periode_in_jaren: {
              title: 'Oorspronkelijk rentevastperiode in jaren',
              description: '',
              value: null,
              type: 'number',
              error: null
            },
            opgebouwde_spaarwaarde: {
              title: 'Opgebouwde (spaar)waarde',
              description: '',
              value: null,
              type: 'amount',
              error: null
            }
          })
        })
        customer.mortgages = mortgages2
      }
    }

    // Pensioenen
    if (data.summary.data.pensioenen) {
      const pensioenen = data.summary.data.pensioenen
      let totalen = {}
      let totalenTotOverlijden = {}

      if (pensioenen.pensioenTotalen && pensioenen.pensioenTotalen.val && pensioenen.pensioenTotalen.val[0]) {
        totalen = pensioenen.pensioenTotalen.val[0]

        if (pensioenen.pensioenTotalen.val.find(pensioenTotalen => pensioenTotalen.totOverlijden) !== undefined) {
          // See https://rapideinternet.atlassian.net/browse/DD-21272
          // totalenTotOverlijden = pensioenen.pensioenTotalen.val.find(pensioenTotalen => pensioenTotalen.aowGerechtigd)
          totalenTotOverlijden = pensioenen.pensioenTotalen.val.sort(function (a, b) {
            if (a.vanafLeeftijdJaren < b.vanafLeeftijdJaren) return 1
            if (a.vanafLeeftijdJaren > b.vanafLeeftijdJaren) return -1

            return 0
          }).filter(pensioen => pensioen.totOverlijden).shift()
        }
      }

      let partnerTotalenTotPensioenLeeftijd = {}

      if (pensioenen.partnerPensioenTotalen && pensioenen.partnerPensioenTotalen.val && pensioenen.partnerPensioenTotalen.val[0]) {
        if (pensioenen.partnerPensioenTotalen.val.find(partnerPensioenTotalen => partnerPensioenTotalen.totOverlijden === false) !== undefined) {
          partnerTotalenTotPensioenLeeftijd = pensioenen.partnerPensioenTotalen.val.find(pensioenTotalen => pensioenTotalen.totOverlijden === false)
          // See https://rapideinternet.atlassian.net/browse/DD-21272
          // partnerTotalenTotPensioenLeeftijd = pensioenen.partnerPensioenTotalen.val.sort(function (a, b) {
          //     if (a.vanafLeeftijdJaren < b.vanafLeeftijdJaren) return 1
          //     if (a.vanafLeeftijdJaren > b.vanafLeeftijdJaren) return -1
          //
          //     return 0
          //   }).filter(pensioen => pensioen.totOverlijden).shift()
        }
      }

      customer.pensions = {
        aow_uitkering: {
          title: 'AOW-uitkering',
          description: '',
          value: totalen.aowUitkering ? totalen.aowUitkering : 0,
          type: 'option',
          options: [
            { value: 1, label: 'Ja', show: true },
            { value: 0, label: 'Nee', show: true }
          ],
          error: null
        },
        aow_gerechtigd: {
          title: 'AOW-gerechtigd',
          description: '',
          value: totalen.aowGerechtigd || true,
          type: 'option',
          options: [
            { value: 1, label: 'Ja', show: true },
            { value: 0, label: 'Nee', show: true }
          ],
          error: null
        },
        aow_alleenstaand: {
          title: 'AOW alleenstaand',
          description: '',
          value: totalenTotOverlijden.aowAlleenstaand,
          type: 'number',
          error: null
        },
        aow_samenwonend: {
          title: 'AOW samenwonend',
          description: '',
          value: totalenTotOverlijden.aowSamenwonend,
          type: 'number',
          error: null
        },
        bruto_pensioenbedrag: {
          title: 'Bruto werknemerspensioen per jaar',
          description: '',
          value: totalenTotOverlijden.brutoPensioenBedrag,
          type: 'number',
          error: null
        },
        bruto_partnerpensioenbedrag: {
          title: 'Bruto partnerpensioen per jaar',
          description: '',
          value: partnerTotalenTotPensioenLeeftijd.verzekerdBedrag,
          type: 'number',
          error: null
        },
        vanaf_leeftijd_jaren: {
          title: 'Vanaf leeftijd in jaren',
          description: '',
          value: totalenTotOverlijden.vanafLeeftijdJaren,
          type: 'number',
          error: null
        },
        vanaf_leeftijd_maanden: {
          title: 'Vanaf leeftijd in maand',
          description: '',
          value: totalenTotOverlijden.vanafLeeftijdMaanden,
          type: 'number',
          error: null
        },
        tot_overlijden: {
          title: 'Tot overlijden',
          description: '',
          value: totalenTotOverlijden.totOverlijden,
          type: 'option',
          options: [
            { value: 1, label: 'Ja', show: true },
            { value: 0, label: 'Nee', show: true }
          ],
          error: null
        }
      }
    }

    // Files
    state.files = []
    if (data.summaryPdf) {
      state.files.push({
        filename: 'overzicht-opgehaalde-gegevens',
        type: 'PDF',
        payload: encodeURI(data.summaryPdf)
      })
    }
    data.blobs.forEach(blob => {
      state.files.push({
        filename: blob.data.parserId,
        type: blob.data.type,
        payload: blob.data.payload
      })
    })

    // Override state
    state = {
      status: {
        loading: false,
        version: initialState.status.version
      },
      style: state.style,
      hdn_companies: state.hdn_companies,
      hdn_company_labels: state.hdn_company_labels,
      files: state.files,
      customer,
      personal_characteristics: personalCharacteristics,
      current_living_condition: currentLivingCondition,
      moving,
      children,
      unemployment,
      incapacity_work: incapacityWork,
      death_of_partner: deathOfPartner,
      divorce,
      pension,
      mortgage,
      questions
    }
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validatePersonalDetails (data) {
    let validation = true
    if (data.customer.salutation.value.length === 0) {
      state.customer.salutation.error = 'Vul uw aanhef in'
      validation = false
    } else {
      state.customer.salutation.error = null
    }
    if (state.isHypotheekwacht && data.customer.initials.value.length === 0) {
      state.customer.initials.error = 'Vul uw initialen in'
      validation = false
    } else {
      state.customer.initials.error = null
    }
    if (!state.isHypotheekwacht && data.customer.first_name.value.length === 0) {
      state.customer.first_name.error = 'Vul uw roepnaam in'
      validation = false
    } else {
      state.customer.first_name.error = null
    }

    if (data.customer.last_name.value.length === 0) {
      state.customer.last_name.error = 'Vul uw achternaam in'
      validation = false
    } else {
      state.customer.last_name.error = null
    }
    if (data.customer.gender.value !== 'm' && data.customer.gender.value !== 'f') {
      state.customer.gender.error = 'Vul uw geslacht in'
      validation = false
    } else {
      state.customer.gender.error = null
    }
    if (data.customer.maritial_status.value === -1) {
      state.customer.maritial_status.error = 'Vul uw burgerlijke staat in'
      validation = false
    } else {
      state.customer.maritial_status.error = null
    }
    if (!data.customer.date_of_birth.value || data.customer.date_of_birth.value.length === 0) {
      state.customer.date_of_birth.error = 'Vul uw geboortedatum in'
      validation = false
    } else {
      state.customer.date_of_birth.error = null
    }
    if (!data.customer.address.street.value || data.customer.address.street.value.length === 0) {
      state.customer.address.street.error = 'Dit veld is verplicht'
      validation = false
    } else {
      state.customer.address.street.error = null
    }
    if (!data.customer.address.number.value || data.customer.address.number.value.length === 0) {
      state.customer.address.number.error = 'Dit veld is verplicht'
      validation = false
    } else {
      state.customer.address.number.error = null
    }
    if (!data.customer.address.postal_code.value || data.customer.address.postal_code.value.length === 0) {
      state.customer.address.postal_code.error = 'Dit veld is verplicht'
      validation = false
    } else {
      state.customer.address.postal_code.error = null
    }
    if (!data.customer.address.place.value || data.customer.address.place.value.length === 0) {
      state.customer.address.place.error = 'Dit veld is verplicht'
      validation = false
    } else {
      state.customer.address.place.error = null
    }

    // Partner
    if (data.customer.has_partner.value === 1 && (state.isHypotheekwacht || (!state.isHypotheekwacht && data.customer.has_mortgage_with_partner.value === 1))) {
      if (!state.isHypotheekwacht && data.customer.partner.first_name.value.length === 0) {
        state.customer.partner.first_name.error = 'Vul uw partner\'s roepnaam in'
        validation = false
      } else {
        state.customer.partner.first_name.error = null
      }
      if (!state.isHypotheekwacht && data.customer.partner.last_name.value.length === 0) {
        state.customer.partner.last_name.error = 'Vul uw partner\'s achternaam in'
        validation = false
      } else {
        state.customer.partner.last_name.error = null
      }
      if (!state.isHypotheekwacht && data.customer.partner.gender.value !== 'm' && data.customer.partner.gender.value !== 'f') {
        state.customer.partner.gender.error = 'Vul uw partner\'s geslacht in'
        validation = false
      } else {
        state.customer.partner.gender.error = null
      }
      if (!state.isHypotheekwacht && data.customer.partner.email.value.length === 0) {
        state.customer.partner.email.error = 'Vul uw partner\'s e-mailadres in'
        validation = false
      } else {
        state.customer.partner.email.error = null
      }
      if (!data.customer.partner.date_of_birth.value || data.customer.partner.date_of_birth.value.length === 0) {
        state.customer.partner.date_of_birth.error = 'Vul uw partner\'s geboortedatum in'
        validation = false
      } else {
        state.customer.partner.date_of_birth.error = null
      }
      if (!state.isHypotheekwacht && data.customer.partner.salaris_bruto_per_maand.value.length === 0) {
        state.customer.partner.salaris_bruto_per_maand.error = 'Vul uw partner\'s salaris in'
        validation = false
      } else if (
        !state.isHypotheekwacht &&
        (
          data.customer.partner.salaris_bruto_per_maand.value < 0 ||
          data.customer.partner.salaris_bruto_per_maand.value > 50000000
        )
      ) {
        state.customer.partner.salaris_bruto_per_maand.error = 'Ongeldige waarde'
        validation = false
      } else {
        state.customer.partner.salaris_bruto_per_maand.error = null
      }
    }

    // Children
    if (data.customer.has_children.value === 1) {
      data.customer.children.forEach((child, i) => {
        if (child.first_name.value.length === 0) {
          state.customer.children[i].first_name.error = 'Vul een roepnaam in'
          validation = false
        } else {
          state.customer.children[i].first_name.error = null
        }
        if (child.last_name.value.length === 0) {
          state.customer.children[i].last_name.error = 'Vul een achternaam in'
          validation = false
        } else {
          state.customer.children[i].last_name.error = null
        }
        if (child.gender.value !== 'm' && child.gender.value !== 'f') {
          state.customer.children[i].gender.error = 'Vul een geslacht in'
          validation = false
        } else {
          state.customer.children[i].gender.error = null
        }
        if (!child.date_of_birth.value || child.date_of_birth.value.length === 0) {
          state.customer.children[i].date_of_birth.error = 'Vul een geboortedatum in'
          validation = false
        } else {
          state.customer.children[i].date_of_birth.error = null
        }
        if (child.studies.value === -1) {
          state.customer.children[i].studies.error = 'Maak een keuze'
          validation = false
        } else {
          state.customer.children[i].studies.error = null
        }
        if (child.lives_at_home.value === -1) {
          state.customer.children[i].lives_at_home.error = 'Maak een keuze'
          validation = false
        } else {
          state.customer.children[i].lives_at_home.error = null
        }
      })
    }

    // Employers
    if (data.customer.has_employers.value === -1) {
      state.customer.has_employers.error = 'Bent u in loondienst?'
      validation = false
    } else if (data.customer.has_employers?.value === 1) {
      data.customer.employers.forEach((employer, i) => {
        if (employer?.name?.value?.length === 0) {
          state.customer.employers[i].name.error = 'Vul een werkgever in'
          validation = false
        } else {
          state.customer.employers[i].name.error = null
        }
        if (!state.isHypotheekwacht && (!employer?.date_of_service?.value || employer?.date_of_service?.value?.length === 0)) {
          state.customer.employers[i].date_of_service.error = 'Vul een datum in'
          validation = false
        } else {
          state.customer.employers[i].date_of_service.error = null
        }
        if (!state.isHypotheekwacht && employer?.function?.value?.length === 0) {
          state.customer.employers[i].function.error = 'Vul een functie in'
          validation = false
        } else {
          state.customer.employers[i].function.error = null
        }
      })
    } else {
      state.customer.has_employers.error = null
    }

    // Companies
    if (data.customer.has_companies.value === -1) {
      state.customer.has_companies.error = 'Staat u ingeschreven bij de Kamer van Koophandel?'
      validation = false
    } else if (data.customer.has_companies.value === 1) {
      data.customer.companies.forEach((company, i) => {
        if (company.name.value.length === 0) {
          state.customer.companies[i].name.error = 'Vul een naam in'
          validation = false
        } else {
          state.customer.companies[i].name.error = null
        }
        if (company.registration_number.value.length === 0) {
          state.customer.companies[i].registration_number.error = 'Vul een KvK-nummer in'
          validation = false
        } else {
          state.customer.companies[i].registration_number.error = null
        }
        if (company.legal_type.value === 0) {
          state.customer.companies[i].legal_type.error = 'Vul een rechtsvorm in'
          validation = false
        } else {
          state.customer.companies[i].legal_type.error = null
        }
        if (!company.start_date.value || company.start_date.value.length === 0) {
          state.customer.companies[i].start_date.error = 'Vul een startdatum in'
          validation = false
        } else {
          state.customer.companies[i].start_date.error = null
        }
      })
    } else {
      state.customer.has_companies.error = null
    }
    state.status.personal_details = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validatePersonalCharacteristics (data) {
    const validation = true
    // if (
    //   data.personal_characteristics.question_1.value === 2 &&
    //   data.personal_characteristics.question_2.value === 2 &&
    //   data.personal_characteristics.question_3.value === 2 &&
    //   data.personal_characteristics.question_4.value === 2
    // ) {
    //   state.personal_characteristics.question_1.error = 'Maak een keuze'
    //   state.personal_characteristics.question_2.error = 'Maak een keuze'
    //   state.personal_characteristics.question_3.error = 'Maak een keuze'
    //   state.personal_characteristics.question_4.error = 'Maak een keuze'
    //   validation = false
    // } else {
    //   state.personal_characteristics.question_1.error = null
    //   state.personal_characteristics.question_2.error = null
    //   state.personal_characteristics.question_3.error = null
    //   state.personal_characteristics.question_4.error = null
    // }
    state.status.personal_characteristics = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateCurrentLivingCondition (data) {
    let validation = true
    if (data.current_living_condition.sections.section_1.questions.i_have_a_house.value === -1) {
      state.current_living_condition.sections.section_1.questions.i_have_a_house.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_1.questions.i_have_a_house.error = null
    }
    if (data.current_living_condition.sections.section_1.questions.i_want_a_new_house.value === -1) {
      state.current_living_condition.sections.section_1.questions.i_want_a_new_house.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_1.questions.i_want_a_new_house.error = null
    }
    if (data.current_living_condition.sections.section_1.questions.i_want_a_new_house.value === 0 &&
      data.current_living_condition.sections.section_1.questions.i_want_a_existing_house.value === -1
    ) {
      state.current_living_condition.sections.section_1.questions.i_want_a_existing_house.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_1.questions.i_want_a_existing_house.error = null
    }
    if (data.current_living_condition.sections.section_1.questions.i_want_a_new_house.value === 0 &&
      data.current_living_condition.sections.section_1.questions.new_house.value === -1
    ) {
      state.current_living_condition.sections.section_1.questions.new_house.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_1.questions.new_house.error = null
    }
    if (data.customer.has_partner.value === 1 &&
      data.current_living_condition.sections.section_3.questions.whos_the_owner.value === -1
    ) {
      state.current_living_condition.sections.section_3.questions.whos_the_owner.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_3.questions.whos_the_owner.error = null
    }
    if (data.customer.has_partner.value === 1 &&
      data.current_living_condition.sections.section_3.questions.whos_income.value === -1) {
      state.current_living_condition.sections.section_3.questions.whos_income.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_3.questions.whos_income.error = null
    }
    if (data.customer.has_partner.value === 1 &&
      data.current_living_condition.sections.section_4.questions.live_style.value === -1
    ) {
      state.current_living_condition.sections.section_4.questions.live_style.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.live_style.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.my_income_5_years.value === -1) {
      state.current_living_condition.sections.section_4.questions.my_income_5_years.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.my_income_5_years.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.moving.value === -1) {
      state.current_living_condition.sections.section_4.questions.moving.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.moving.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.heritage.value === -1) {
      state.current_living_condition.sections.section_4.questions.heritage.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.heritage.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.family_expansion.value === -1) {
      state.current_living_condition.sections.section_4.questions.family_expansion.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.family_expansion.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.study_costs_children.value === -1) {
      state.current_living_condition.sections.section_4.questions.study_costs_children.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.study_costs_children.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.working_less.value === -1) {
      state.current_living_condition.sections.section_4.questions.working_less.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.working_less.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.other_possibility.value === -1) {
      state.current_living_condition.sections.section_4.questions.other_possibility.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.other_possibility.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.low_mortgage.value === -1) {
      state.current_living_condition.sections.section_4.questions.low_mortgage.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_4.questions.low_mortgage.error = null
    }
    if (data.current_living_condition.sections.section_5.questions.goal.value === -1) {
      state.current_living_condition.sections.section_5.questions.goal.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_5.questions.goal.error = null
    }
    if (data.current_living_condition.sections.section_4.questions.saving_goals.required > 0) {
      let answers = 0
      data.current_living_condition.sections.section_4.questions.saving_goals.questions.forEach((question, q) => {
        if (question.value === 1) {
          answers++
        }
      })
      if (data.current_living_condition.sections.section_4.questions.saving_goals.required > answers) {
        state.current_living_condition.sections.section_4.questions.saving_goals.error =
          `Minimaal ${data.current_living_condition.sections.section_4.questions.saving_goals.required} antwoord(en)`
        validation = false
      } else {
        state.current_living_condition.sections.section_4.questions.saving_goals.error = null
      }
    }

    if (data.current_living_condition.sections.section_4.questions.loans_and_liabilities.required > 0) {
      let answers = 0
      data.current_living_condition.sections.section_4.questions.loans_and_liabilities.questions.forEach((question, q) => {
        if (question.value === 1) {
          answers++
        }
      })
      if (data.current_living_condition.sections.section_4.questions.loans_and_liabilities.required > answers) {
        state.current_living_condition.sections.section_4.questions.loans_and_liabilities.error =
          `Minimaal ${data.current_living_condition.sections.section_4.questions.loans_and_liabilities.required} antwoord(en)`
        validation = false
      } else {
        state.current_living_condition.sections.section_4.questions.loans_and_liabilities.error = null
      }
    }

    if (data.current_living_condition.sections.section_6.questions.have_will.value === -1) {
      state.current_living_condition.sections.section_6.questions.have_will.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_6.questions.have_will.error = null
    }
    if (data.current_living_condition.sections.section_6.questions.have_life_will.value === -1) {
      state.current_living_condition.sections.section_6.questions.have_life_will.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_6.questions.have_life_will.error = null
    }
    if (data.current_living_condition.sections.section_6.questions.is_will_old.value === -1) {
      state.current_living_condition.sections.section_6.questions.is_will_old.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_6.questions.is_will_old.error = null
    }
    if (data.current_living_condition.sections.section_6.questions.have_will_advice.value === -1) {
      state.current_living_condition.sections.section_6.questions.have_will_advice.error = 'Maak een keuze'
      validation = false
    } else {
      state.current_living_condition.sections.section_6.questions.have_will_advice.error = null
    }

    state.status.current_living_condition = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateFinancing (data) {
    let validation = true
    if (data.financing.sections.section_11.questions.mortgage_payments.value === -1) {
      state.financing.sections.section_11.questions.mortgage_payments.error = 'Maak een keuze'
      validation = false
    } else {
      state.financing.sections.section_11.questions.mortgage_payments.error = null
    }

    if (data.financing.sections.section_11.questions.mortgage_properties.required > 0) {
      let answers = 0
      data.financing.sections.section_11.questions.mortgage_properties.questions.forEach((question, q) => {
        if (question.value === 1) {
          answers++
        }
      })
      if (data.financing.sections.section_11.questions.mortgage_properties.required > answers) {
        state.financing.sections.section_11.questions.mortgage_properties.error = `Minimaal ${data.financing.sections.section_11.questions.mortgage_properties.required} antwoord(en)`
        validation = false
      } else {
        state.financing.sections.section_11.questions.mortgage_properties.error = null
      }
    }
    state.status.financing = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateMortgageAndPension (data) {
    // TODO implement
    let validation = true

    // Hypotheken
    for (const i in data.customer.mortgages) {
      // Geldverstrekker
      if (data.customer.mortgages[i].hdn_company_id.value === 'none') {
        state.customer.mortgages[i].hdn_company_id.error = 'Maak een keuze'
        validation = false
      } else {
        state.customer.mortgages[i].hdn_company_id.error = null
      }

      // Product
      if (data.customer.mortgages[i].hdn_company_label_id.value === 'none') {
        state.customer.mortgages[i].hdn_company_label_id.error = 'Maak een keuze'
        validation = false
      } else {
        state.customer.mortgages[i].hdn_company_label_id.error = null
      }

      // Leningdelen
      for (const j in data.customer.mortgages[i].parts) {
        // Aflosvorm
        if (data.customer.mortgages[i].parts[j].aflosvorm.value === 'none') {
          state.customer.mortgages[i].parts[j].aflosvorm.error = 'Maak een keuze'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].aflosvorm.error = null
        }

        // Leningdeelnummer
        if (data.customer.mortgages[i].parts[j].leningdeelnummer.value === null || data.customer.mortgages[i].parts[j].leningdeelnummer.value === '') {
          state.customer.mortgages[i].parts[j].leningdeelnummer.error = 'Vul een leningdeelnummer in'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].leningdeelnummer.error = null
        }

        // Ingangsdatum leningdeel
        if (data.customer.mortgages[i].parts[j].ingangsdatum_leningdeel.value === null || data.customer.mortgages[i].parts[j].ingangsdatum_leningdeel.value === '') {
          state.customer.mortgages[i].parts[j].ingangsdatum_leningdeel.error = 'Vul de' +
            ' ingangsdatum van het leningdeel in'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].ingangsdatum_leningdeel.error = null
        }

        // Einddatum leningdeel
        if (data.customer.mortgages[i].parts[j].einddatum_leningdeel.value === null || data.customer.mortgages[i].parts[j].einddatum_leningdeel.value === '') {
          state.customer.mortgages[i].parts[j].einddatum_leningdeel.error = 'Vul de' +
            ' einddatum van het leningdeel in'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].einddatum_leningdeel.error = null
        }

        // Oorspronkelijke hoogte leningdeel
        if (data.customer.mortgages[i].parts[j].oorspronkelijke_hoogte_leningdeel.value === null || data.customer.mortgages[i].parts[j].oorspronkelijke_hoogte_leningdeel.value === '') {
          state.customer.mortgages[i].parts[j].oorspronkelijke_hoogte_leningdeel.error = 'Vul de' +
            ' oorspronkelijke hoogte van het leningdeel in'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].oorspronkelijke_hoogte_leningdeel.error = null
        }

        // Soort rente
        if (data.customer.mortgages[i].parts[j].interest_type.value === 'none') {
          state.customer.mortgages[i].parts[j].interest_type.error = 'Maak een keuze'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].interest_type.error = null
        }

        // Rentepercentage
        if (data.customer.mortgages[i].parts[j].rentepercentage.value === null || data.customer.mortgages[i].parts[j].rentepercentage.value === '') {
          state.customer.mortgages[i].parts[j].rentepercentage.error = 'Vul een rente in'
          validation = false
        } else if (
          data.customer.mortgages[i].parts[j].rentepercentage.value !== null &&
          data.customer.mortgages[i].parts[j].rentepercentage.value !== '' &&
          parseFloat(data.customer.mortgages[i].parts[j].rentepercentage.value) < 0
        ) {
          state.customer.mortgages[i].parts[j].rentepercentage.error = 'Rente mag niet negatief zijn'
          validation = false
        } else if (
          data.customer.mortgages[i].parts[j].rentepercentage.value !== null &&
          data.customer.mortgages[i].parts[j].rentepercentage.value !== '' &&
          parseFloat(data.customer.mortgages[i].parts[j].rentepercentage.value) > 100
        ) {
          state.customer.mortgages[i].parts[j].rentepercentage.error = 'Rente is een te groot getal'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].rentepercentage.error = null
        }

        // Einddatum rentevastperiode
        if (data.customer.mortgages[i].parts[j].einddatum_rentevastperiode.value === null || data.customer.mortgages[i].parts[j].einddatum_rentevastperiode.value === '') {
          state.customer.mortgages[i].parts[j].einddatum_rentevastperiode.error = 'Vul een einddatum in'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].einddatum_rentevastperiode.error = null
        }

        // Rentevastperiode
        if (data.customer.mortgages[i].parts[j].rentevast_periode_in_jaren.value === null || data.customer.mortgages[i].parts[j].rentevast_periode_in_jaren.value === '') {
          state.customer.mortgages[i].parts[j].rentevast_periode_in_jaren.error = 'Vul het' +
            ' aantal rentevaste jaren in'
          validation = false
        } else {
          state.customer.mortgages[i].parts[j].rentevast_periode_in_jaren.error = null
        }
      }
    }

    // state.customer.mortgages.hdn_company_id.error

    state.status.mortgage_and_pension = validation

    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateMortgage (data) {
    let validation = true
    if (data.mortgage.sections.section_21.questions.question_1.value === -1) {
      state.mortgage.sections.section_21.questions.question_1.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_1.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_2.value === -1) {
      state.mortgage.sections.section_21.questions.question_2.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_2.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_3.value === -1) {
      state.mortgage.sections.section_21.questions.question_3.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_3.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_4.value === -1) {
      state.mortgage.sections.section_21.questions.question_4.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_4.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_5.value === -1) {
      state.mortgage.sections.section_21.questions.question_5.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_5.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_6.value === -1) {
      state.mortgage.sections.section_21.questions.question_6.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_6.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_7.value === -1) {
      state.mortgage.sections.section_21.questions.question_7.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_7.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_8.value === -1) {
      state.mortgage.sections.section_21.questions.question_8.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_8.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_9.value === -1) {
      state.mortgage.sections.section_21.questions.question_9.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_9.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_10.value === -1) {
      state.mortgage.sections.section_21.questions.question_10.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_10.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_11.value === -1) {
      state.mortgage.sections.section_21.questions.question_11.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_11.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_12.value === -1) {
      state.mortgage.sections.section_21.questions.question_12.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_12.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_13.value === -1) {
      state.mortgage.sections.section_21.questions.question_13.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_13.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_14.value === -1) {
      state.mortgage.sections.section_21.questions.question_14.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_14.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_15.value === -1) {
      state.mortgage.sections.section_21.questions.question_15.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_15.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_16.value === -1) {
      state.mortgage.sections.section_21.questions.question_16.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_16.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_17.value === -1) {
      state.mortgage.sections.section_21.questions.question_17.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_17.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_18.value === -1) {
      state.mortgage.sections.section_21.questions.question_18.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_18.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_19.value === -1) {
      state.mortgage.sections.section_21.questions.question_19.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_19.error = null
    }
    if (data.mortgage.sections.section_21.questions.question_20.value === -1) {
      state.mortgage.sections.section_21.questions.question_20.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.question_20.error = null
    }
    if (data.mortgage.sections.section_21.questions.form_saving.value === -1) {
      state.mortgage.sections.section_21.questions.form_saving.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.form_saving.error = null
    }
    if (data.mortgage.sections.section_21.questions.experience.value === -1) {
      state.mortgage.sections.section_21.questions.experience.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_21.questions.experience.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_1.value === -1) {
      state.mortgage.sections.section_22.questions.question_1.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_1.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_2.value === -1) {
      state.mortgage.sections.section_22.questions.question_2.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_2.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_3.value === -1) {
      state.mortgage.sections.section_22.questions.question_3.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_3.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_4.value === -1) {
      state.mortgage.sections.section_22.questions.question_4.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_4.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_5.value === -1) {
      state.mortgage.sections.section_22.questions.question_5.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_5.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_6.value === -1) {
      state.mortgage.sections.section_22.questions.question_6.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_6.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_7.value === -1) {
      state.mortgage.sections.section_22.questions.question_7.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_7.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_8.value === -1) {
      state.mortgage.sections.section_22.questions.question_8.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_8.error = null
    }
    if (data.mortgage.sections.section_22.questions.question_9.value === -1) {
      state.mortgage.sections.section_22.questions.question_9.error = 'Maak een keuze'
      validation = false
    } else {
      state.mortgage.sections.section_22.questions.question_9.error = null
    }
    state.status.mortgage = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateMoving (data) {
    let validation = true
    if (data.moving.sections.section_31.questions.i_expect_to_move.value === -1) {
      state.moving.sections.section_31.questions.i_expect_to_move.error = 'Maak een keuze'
      validation = false
    } else {
      state.moving.sections.section_31.questions.i_expect_to_move.error = null
    }
    state.status.moving = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateChildren (data) {
    let validation = true
    if (data.customer.has_children.value === 1) {
      if (data.children.sections.section_41.questions.family_expansion.value === -1) {
        state.children.sections.section_41.questions.family_expansion.error = 'Maak een keuze'
        validation = false
      } else {
        state.children.sections.section_41.questions.family_expansion.error = null
      }
    }
    state.status.children = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateUnemployment (data) {
    let validation = true
    if (data.unemployment.sections.section_51.questions.current_home.value === -1) {
      state.unemployment.sections.section_51.questions.current_home.error = 'Maak een keuze'
      validation = false
    } else {
      state.unemployment.sections.section_51.questions.current_home.error = null
    }
    if (data.unemployment.sections.section_51.questions.solution.value === -1) {
      state.unemployment.sections.section_51.questions.solution.error = 'Maak een keuze'
      validation = false
    } else {
      state.unemployment.sections.section_51.questions.solution.error = null
    }

    if (data.unemployment.sections.section_51.questions.becoming_unemployed.required > 0) {
      let answers = 0
      data.unemployment.sections.section_51.questions.becoming_unemployed.questions.forEach((question, q) => {
        if (question.value === 1) {
          answers++
        }
      })
      if (data.unemployment.sections.section_51.questions.becoming_unemployed.required > answers) {
        state.unemployment.sections.section_51.questions.becoming_unemployed.error = `Minimaal ${data.unemployment.sections.section_51.questions.becoming_unemployed.required} antwoord(en)`
        validation = false
      } else {
        state.unemployment.sections.section_51.questions.becoming_unemployed.error = null
      }
    }
    if (getAge(data.customer.date_of_birth.value) > 65) {
      validation = true
    }
    state.status.unemployment = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateIncapacityWork (data) {
    let validation = true
    if (data.incapacity_work.sections.section_61.questions.current_home.value === -1) {
      state.incapacity_work.sections.section_61.questions.current_home.error = 'Maak een keuze'
      validation = false
    } else {
      state.incapacity_work.sections.section_61.questions.current_home.error = null
    }
    if (data.incapacity_work.sections.section_61.questions.insurance_type.value === -1) {
      state.incapacity_work.sections.section_61.questions.insurance_type.error = 'Maak een keuze'
      validation = false
    } else {
      state.incapacity_work.sections.section_61.questions.insurance_type.error = null
    }
    if (data.customer.has_partner.value === 1 && data.incapacity_work.sections.section_61.questions.solution.value === -1) {
      state.incapacity_work.sections.section_61.questions.solution.error = 'Maak een keuze'
      validation = false
    } else {
      state.incapacity_work.sections.section_61.questions.solution.error = null
    }

    if (data.incapacity_work.sections.section_61.questions.becoming_incapacitated.required > 0) {
      let answers = 0
      data.incapacity_work.sections.section_61.questions.becoming_incapacitated.questions.forEach((question, q) => {
        if (question.value === 1) {
          answers++
        }
      })
      if (data.incapacity_work.sections.section_61.questions.becoming_incapacitated.required > answers) {
        state.incapacity_work.sections.section_61.questions.becoming_incapacitated.error = `Minimaal ${data.incapacity_work.sections.section_61.questions.becoming_incapacitated.required} antwoord(en)`
        validation = false
      } else {
        state.incapacity_work.sections.section_61.questions.becoming_incapacitated.error = null
      }
    }
    if (getAge(data.customer.date_of_birth.value) > 65) {
      validation = true
    }

    state.status.incapacity_work = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateHousingRequirements (data) {
    state.status.housing_requirements = true
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateSustainability (data) {
    let validation = true

    if (data.sustainability.sections.section_1.questions.energy_saving_measures.value === -1) {
      data.sustainability.sections.section_1.questions.energy_saving_measures.error = 'Maak een keuze'
      validation = false
    } else {
      data.sustainability.sections.section_1.questions.energy_saving_measures.error = null
    }

    if (data.sustainability.sections.section_1.questions.savings.value === -1) {
      data.sustainability.sections.section_1.questions.savings.error = 'Maak een keuze'
      validation = false
    } else {
      data.sustainability.sections.section_1.questions.savings.error = null
    }

    if (data.sustainability.sections.section_1.questions.financing_sustainability.value === -1) {
      data.sustainability.sections.section_1.questions.financing_sustainability.error = 'Maak een keuze'
      validation = false
    } else {
      data.sustainability.sections.section_1.questions.financing_sustainability.error = null
    }

    if (data.sustainability.sections.section_2.questions.interested_sustainability_housing.value === -1) {
      data.sustainability.sections.section_2.questions.interested_sustainability_housing.error = 'Maak een keuze'
      validation = false
    } else {
      data.sustainability.sections.section_2.questions.interested_sustainability_housing.error = null
    }

    if (data.sustainability.sections.section_2.questions.options_sustainability_housing_mapping.value === -1) {
      data.sustainability.sections.section_2.questions.options_sustainability_housing_mapping.error = 'Maak een keuze'
      validation = false
    } else {
      data.sustainability.sections.section_2.questions.options_sustainability_housing_mapping.error = null
    }

    if (data.sustainability.sections.section_2.questions.report_sustainability_housing.value === -1) {
      data.sustainability.sections.section_2.questions.report_sustainability_housing.error = 'Maak een keuze'
      validation = false
    } else {
      data.sustainability.sections.section_2.questions.report_sustainability_housing.error = null
    }

    if (data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.value === -1) {
      data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.error = 'Maak een keuze'
      validation = false
    } else {
      data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.error = null
    }

    if (data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.value === 1) {
      if (data.sustainability.sections.section_4.questions.financing_sustainability.value === -1) {
        data.sustainability.sections.section_4.questions.financing_sustainability.error = 'Maak een keuze'
        validation = false
      } else {
        data.sustainability.sections.section_4.questions.financing_sustainability.error = null
      }
    }

    state.status.sustainability = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateDeathOfPartner (data) {
    let validation = true

    if (data.customer.has_partner.value === 1) {
      if (data.death_of_partner.sections.section_71.questions.current_home.value === -1) {
        state.death_of_partner.sections.section_71.questions.current_home.error = 'Maak een keuze'
        validation = false
      } else {
        state.death_of_partner.sections.section_71.questions.current_home.error = null
      }
      if (data.death_of_partner.sections.section_71.questions.solution.value === -1) {
        state.death_of_partner.sections.section_71.questions.solution.error = 'Maak een keuze'
        validation = false
      } else {
        state.death_of_partner.sections.section_71.questions.solution.error = null
      }
      if (data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.required > 0) {
        let answers = 0
        data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.questions.forEach((question, q) => {
          if (question.value === 1) {
            answers++
          }
        })
        if (data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.required > answers) {
          state.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.error = `Minimaal ${data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.required} antwoord(en)`
          validation = false
        } else {
          state.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.error = null
        }
      }

      if (data.death_of_partner.sections.section_71.questions.partner_dies.required > 0) {
        let answers = 0
        data.death_of_partner.sections.section_71.questions.partner_dies.questions.forEach((question, q) => {
          if (question.value === 1) {
            answers++
          }
        })
        if (data.death_of_partner.sections.section_71.questions.partner_dies.required > answers) {
          state.death_of_partner.sections.section_71.questions.partner_dies.error = `Minimaal ${data.death_of_partner.sections.section_71.questions.partner_dies.required} antwoord(en)`
          validation = false
        } else {
          state.death_of_partner.sections.section_71.questions.partner_dies.error = null
        }
      }
    }
    state.status.death_of_partner = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateDivorce (data) {
    let validation = true
    if (data.customer.has_partner.value === 1) {
      if (data.divorce.sections.section_81.questions.current_home.value === -1) {
        state.divorce.sections.section_81.questions.current_home.error = 'Maak een keuze'
        validation = false
      } else {
        state.divorce.sections.section_81.questions.current_home.error = null
      }
    }
    state.status.divorce = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validatePension (data) {
    let validation = true
    if (data.pension.sections.section_91.questions.current_home.value === -1) {
      state.pension.sections.section_91.questions.current_home.error = 'Maak een keuze'
      validation = false
    } else {
      state.pension.sections.section_91.questions.current_home.error = null
    }
    if (data.pension.sections.section_91.questions.moving.value === -1) {
      state.pension.sections.section_91.questions.moving.error = 'Maak een keuze'
      validation = false
    } else {
      state.pension.sections.section_91.questions.moving.error = null
    }
    if (data.pension.sections.section_91.questions.heritage.value === -1) {
      state.pension.sections.section_91.questions.heritage.error = 'Maak een keuze'
      validation = false
    } else {
      state.pension.sections.section_91.questions.heritage.error = null
    }
    if (data.pension.sections.section_91.questions.relevant_change.value === -1) {
      state.pension.sections.section_91.questions.relevant_change.error = 'Maak een keuze'
      validation = false
    } else {
      state.pension.sections.section_91.questions.relevant_change.error = null
    }

    if (data.customer.has_partner.value === 1) {
      if (data.pension.sections.section_92.questions.solution.value === -1) {
        state.pension.sections.section_92.questions.solution.error = 'Maak een keuze'
        validation = false
      } else {
        state.pension.sections.section_92.questions.solution.error = null
      }

      if (data.pension.sections.section_91.questions.partner_dies.required > 0) {
        let answers = 0
        data.pension.sections.section_91.questions.partner_dies.questions.forEach((question, q) => {
          if (question.value === 1) {
            answers++
          }
        })
        if (data.pension.sections.section_91.questions.partner_dies.required > answers) {
          state.pension.sections.section_91.questions.partner_dies.error = `Minimaal ${data.pension.sections.section_91.questions.partner_dies.required} antwoord(en)`
          validation = false
        } else {
          state.pension.sections.section_91.questions.partner_dies.error = null
        }
      }
    }

    state.status.pension = validation
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  validateQuestions (data) {
    state.status.questions_for_the_advisor = true
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  },
  submit (state, data) {
    const key = `['${data.name.split('.').join('\'][\'')}']`
    // eslint-disable-next-line
    eval('state = { ...state' + key + ' = data.value }')
    localStorage.setItem('survey', JSON.stringify(this.state.survey))
  }
}

export const surveyModule = {
  namespaced: true,
  state,
  actions,
  mutations
}
